import axios, { AxiosResponse } from "axios";
import { BASE_SERVER } from "../constants";

interface SuccessResponse {
    access_token?: string;
    token_type?: string;
    detail?: string;
}


interface LoginData {
    username: string;
    password: string;
}

export const login = async (username: string, password: string): Promise<AxiosResponse<SuccessResponse>> => {
    const data: LoginData = {
        username: username,
        password: password,
    };
    const formData = new URLSearchParams(Object.entries(data));
    
    try {
        const response: AxiosResponse<SuccessResponse> = await axios.post(`${BASE_SERVER}/login/access-token`, formData, {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        
        return response;
    } catch (error: any) {
        if (error.response) {
            // Server responded with a status code other than 2xx
            return error.response;
        } else if (error.request) {
            // Request was made but no response was received
            return {
                data: { detail: "No response from server" },
                status: 0,
                statusText: '',
                headers: {},
                config: {},
                request: error.request
            } as AxiosResponse<SuccessResponse>;
        } else {
            // Something else happened while setting up the request
            return {
                data: { detail: error.message },
                status: 0,
                statusText: '',
                headers: {},
                config: {},
                request: null
            } as AxiosResponse<SuccessResponse>;
        }
    }
}
