import React, { useContext } from 'react'
import { readNotification } from '../services/readNotification'
import { Notification } from '../types'
import { Context } from '../state/Provider'
import { TiDocumentText } from 'react-icons/ti'
import { IoDocumentTextOutline } from 'react-icons/io5'
import { HiOutlineDocumentDuplicate } from 'react-icons/hi2'

interface props {
    notification: Notification
}

const NotificationItem:React.FC<props> = ({ notification })=> {
    const { setRefreshApplications } = useContext(Context)
    const [isRead, setIsRead] = React.useState<boolean>(notification.is_read)
  return (
    <div
    onClick={async()=>{
        setIsRead(true)
        await readNotification(notification.id)
        setRefreshApplications(s => !s)
    }}
     className={`flex justify-start items-center gap-2 px-4 py-2 border-b-[1px] border-grey-50 cursor-pointer hover:bg-grey-50/50 ${!isRead && 'bg-grey-50/30'}`}>
    <span className='flex justify-center items-center p-2 rounded-md bg-grey-150/20'>
            {notification.notification_type.includes('application') &&  <TiDocumentText className='text-2xl text-black'/>}
            {notification.notification_type.includes('document') &&  <IoDocumentTextOutline className='text-2xl text-black'/>}
            {notification.notification_type.includes('checklist') &&  <HiOutlineDocumentDuplicate className='text-2xl text-black'/>}
    </span>
    <div>
        <p className='text-black text-sm font-medium sm:text-xs'>{notification.message}</p>
        <p className='text-stalle-500 text-xs font-semibold sm:text-[10px]'>{notification.created_at.replace('T', '').substr(0, 16)}</p>
    </div>
</div>
  )
}

export default NotificationItem
