import React from 'react'
import { BsEnvelope } from 'react-icons/bs'
import { FaFacebookF, FaInstagram } from 'react-icons/fa6'
import { IoLogoTiktok } from 'react-icons/io5'
import { MdOutlineLocalPhone } from 'react-icons/md'
import { LiaEnvelopeOpenTextSolid } from "react-icons/lia";
import { SiGooglemaps } from "react-icons/si";

function Footer() {
    return (
        <div className='w-full bg-black'>
            <div className='px-14 pt-14 pb-10 flex justify-between items-start sm:px-5 sm:flex-col sm:pt-8 sm:gap-6 sm:pb-10'>
                <div className='flex flex-col gap-5 sm:gap-3'>
                    <img src={require('../assets/logo - Copy.png')} className='w-[200px] cursor-pointer sm:w-[150px]' />
                    <div className='flex justify-start items-center gap-2'>
                        <span className='bg-[#222] p-3 rounded-full flex justify-center items-center cursor-pointer border-[1px] border-stalle-500/50 sm:p-2'>
                            <BsEnvelope className='text-white text-lg sm:text-base' />
                        </span>
                        <span className='bg-[#222] p-3 rounded-full flex justify-center items-center cursor-pointer border-[1px] border-stalle-500/50 sm:p-2'>
                            <FaFacebookF className='text-white text-lg sm:text-base' />
                        </span>
                        <span className='bg-[#222] p-3 rounded-full flex justify-center items-center cursor-pointer border-[1px] border-stalle-500/50 sm:p-2'>
                            <FaInstagram className='text-white text-lg  sm:text-base' />
                        </span>
                        <span className='bg-[#222] p-3 rounded-full flex justify-center items-center cursor-pointer border-[1px] border-stalle-500/50 sm:p-2'>
                            <IoLogoTiktok className='text-white text-lg sm:text-base' />
                        </span>
                    </div>
                </div>

                <div className='flex justify-end items-start gap-16 sm:justify-start sm:flex-wrap sm:gap-8'>
                    <div className='flex flex-col gap-3'>
                        <h1 className='text-white font-bold text-base sm:text-sm'>Products</h1>
                        <nav className='flex flex-col gap-1'>
                            <a className='text-white text-sm font-normal sm:text-xs'>Docurize</a>
                            <a className='text-white text-sm font-normal sm:text-xs'>Docurize Personal</a>
                        </nav>
                    </div>
                    <div className='flex flex-col gap-3'>
                        <h1 className='text-white font-bold text-base sm:text-sm'>Pricing</h1>
                        <nav className='flex flex-col gap-1'>
                            <a className='text-white text-sm font-normal sm:text-xs'>Free</a>
                            <a className='text-white text-sm font-normal sm:text-xs'>Pro</a>
                            <a className='text-white text-sm font-normal sm:text-xs'>Business</a>
                        </nav>
                    </div>
                    <div className='flex flex-col gap-3'>
                        <h1 className='text-white font-bold text-base sm:text-sm'>Consulting</h1>
                        <nav className='flex flex-col gap-1'>
                            <a className='text-white text-sm font-normal sm:text-xs'>Schedule a call</a>
                        </nav>
                    </div>
                    <div className='flex flex-col gap-3 w-60 sm:w-full '>
                        <h1 className='text-white font-bold text-base sm:text-sm'>Contact Us</h1>
                        <div className='flex flex-wrap gap-5 sm:flex-wrap'>
                        <div className='flex flex-col gap-1 justify-start'>
                            <MdOutlineLocalPhone className='text-grey-150 text-lg' />
                            <p className='text-white text-sm font-normal sm:text-xs'>Schedule a call</p>
                        </div>
                        <div className='flex flex-col gap-1 justify-start'>
                            <LiaEnvelopeOpenTextSolid className='text-grey-150 text-lg' />
                            <p className='text-white text-sm font-normal sm:text-xs'>info@veled.in</p>
                        </div>
                        <div className='flex flex-col gap-1 justify-start sm:w-full'>
                            <SiGooglemaps className='text-grey-150 text-lg' />
                            <p className='text-white text-sm font-normal sm:text-xs'>New York, USA</p>
                        </div>
                        <div className='flex flex-col gap-1 justify-start mt-5 w-full sm:mt-0'>
                            <p className='text-white text-xs font-normal sm:text-xs text-right sm:text-left'>{"Made with <3 in Vancouver"}</p>
                        </div>

                        </div>
                    </div>
                </div>

            </div>
            <hr className='w-full h-[2px] bg-stalle-500/50' />
            <div className='flex justify-between items-center py-5 px-14 sm:flex-col sm:gap-2'>
                <p className='text-sm text-white sm:text-xs'>@ {new Date().getFullYear()} All Rights Reserved to Velarize </p>
                <span className='flex justify-end items-center gap-2'>
                    <a className='text-sm text-white sm:text-xs'>Terms & Conditions </a>
                    <span className='h-1 w-1 rounded-full bg-white'></span>
                    <a className='text-sm text-white sm:text-xs'>Privacy Policy </a>
                </span>
            </div>
        </div>
    )
}

export default Footer
