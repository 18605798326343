import React, { useContext, useEffect, useRef } from 'react';
import { CiBellOn } from 'react-icons/ci';
import { HiMiniBars3 } from 'react-icons/hi2';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { RxCross2 } from 'react-icons/rx';
import { Context } from '../state/Provider';
import { Link, useNavigate } from 'react-router-dom';
import { IoArrowBack } from 'react-icons/io5';
import NotificationItem from './NotificationItem';
import { FaRegUser } from 'react-icons/fa6';
import { IoIosLogOut } from 'react-icons/io';

interface Props {
  mobDrawer: boolean;
  setMobDrawer: React.Dispatch<React.SetStateAction<boolean>>;
}

const Navbar: React.FC<Props> = ({ mobDrawer, setMobDrawer }) => {
  const { mobBackBtn, setSelectedChecklist, selectedchecklist, notification } = useContext(Context);
  const navigate = useNavigate();
  const [notificationMenu, setNotificationMenu] = React.useState<boolean>(false);
  const [profileMenu, setProfileMenu] = React.useState<boolean>(false);

  const notiRef = useRef<HTMLDivElement>(null);
  const profileRef = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //   const handleClickOutside = (event: MouseEvent) => {
  //     if (notiRef.current && !notiRef.current.contains(event.target as Node)) {
  //       setTimeout(() => setNotificationMenu(false), 500);
  //       // setNotificationMenu(false)
  //     }
  //     if (profileRef.current && !profileRef.current.contains(event.target as Node)) {
  //       setProfileMenu(false);
  //     }
  //   };

  //   document.addEventListener('mousedown', handleClickOutside);
  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, []);

  return (
    <div className='fixed w-full top-0 left-0 border-b-2 border-black/15 z-50 bg-white'>
      <div className='flex justify-start items-center px-5 gap-12 py-4 sm:py-2 sm:px-3 z-50'>
        <img src={require('../assets/logo.png')} className='w-[200px] cursor-pointer sm:hidden' alt="Logo" />
        <div className='sm:flex justify-start items-center gap-1.5 hidden'>
          {mobBackBtn && (
            <button
              onClick={() => {
                selectedchecklist.id ? setSelectedChecklist({} as any) : navigate(-1);
              }}
              className='hidden outline-none sm:flex cursor-pointer bg-grey-50/40 rounded-full border-[1px] border-grey-150/20 p-1.5 z-[55]'
            >
              <IoArrowBack className='text-black text-md' />
            </button>
          )}
          <button
            onClick={() => { setMobDrawer(!mobDrawer); }}
            className='hidden outline-none sm:flex cursor-pointer bg-grey-50/40 rounded-full border-[1px] border-grey-150/20 p-1.5 z-[55]'
          >
            {!mobDrawer ? <HiMiniBars3 className='text-black text-xl' /> : <RxCross2 className='text-black text-md' />}
          </button>
        </div>

        <div className='flex justify-end items-center w-full'>
          <span className='flex justify-end items-center gap-3'>
            <span onClick={() => setNotificationMenu(s => !s)} className='bg-grey-50/40 rounded-full p-1.5 cursor-pointer border-[1px] border-grey-100 relative'>
              <CiBellOn className='text-2xl text-black/75' />
              {notification.filter((n => !n.is_read)).length > 0 && (
                <span className='absolute top-0 -right-1 bg-blue-400 p-0.5 rounded-full px-1.5'>
                  <p className='text-xs text-white'>{notification.filter((n => !n.is_read)).length}</p>
                </span>
              )}
            </span>

            <span className='cursor-pointer bg-grey-50/40 border-[1px] border-grey-100 px-2 py-1 rounded-2xl flex gap-1 items-center sm:p-1' onClick={() => setProfileMenu(s => (s? false: true))}>
              <img src={require('../assets/Ellipse 2207.png')} className='w-7 h-7 rounded-full' alt="Profile" />
              <p className='text-black font-semibold text-base capitalize sm:hidden'>{JSON.parse(localStorage.getItem('user') || '{}').first_name}</p>
              <MdOutlineKeyboardArrowDown className='text-black text-lg sm:hidden' />
            </span>
          </span>
        </div>
      </div>

      <div ref={notiRef} className={`absolute right-48 shadow-xl w-[400px] rounded-xl sm:right-0 sm:w-full sm:top-12 sm:rounded-none bg-white border-[1px] border-grey-50 z-10 ${notificationMenu ? 'opacity-100 top-20' : 'opacity-0 -top-[1000px] sm:!-top-[10000px]'} transition-all ease-in duration-100`}>
        <h1 className='text-black text-lg font-bold py-3 px-3'>Notifications</h1>
        <hr className='w-full h-[1px] border-t-[1px] border-grey-50' />
        <div className='flex flex-col w-full h-[300px] overflow-y-scroll pb-2 sm:h-[90vh]'>
          {notification?.map((notification, index) => (
            <NotificationItem key={index} notification={notification} />
          ))}
        </div>
      </div>

      <div ref={profileRef} className={`absolute right-10 shadow-xl w-[180px] rounded-xl sm:right-0 sm:w-[200px] sm:top-12 sm:rounded-none bg-white border-[1px] border-grey-50 z-10 ${profileMenu ? 'opacity-100 top-20' : 'opacity-0 -top-[1000px] sm:!-top-[10000px]'} transition-all ease-in duration-100`}>
        <div className='flex flex-col w-full p-3 sm:p-0'>
          <Link to='/personal-info' onClick={() => setProfileMenu(false)} className="py-2 flex justify-start items-center gap-1 text-grey-500 text-base transition-all ease in duration-300 sm:pl-3 sm:text-sm">
            <FaRegUser className="text-lg text-grey-500" />Personal info
          </Link>
          <button
            onClick={() => {
              localStorage.removeItem('token');
              window.location.href = '/';
            }}
            className="py-2 flex justify-start items-center gap-1 text-base text-grey-500 transition-all ease in duration-300 sm:pl-3 sm:text-sm sm:bottom-28"
          >
            <IoIosLogOut className="text-xl text-grey-500" />Log Out
          </button>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
