import React from 'react';
import { IoPlay } from "react-icons/io5";
import OutterNav from '../components/OutterNav';
import { IoMdArrowForward } from "react-icons/io";
import { FaCheck } from "react-icons/fa6";
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';

const Landing: React.FC = () => {
  return (
    <div className='bg-white flex flex-col'>
      <OutterNav />
      <div id='home' className='bg-grey-100 flex justify-between sm:justify-start items-center px-16 pt-14 pb-10 min-h-screen sm:flex-col sm:px-5 sm:gap-8 sm:pt-24'>

        <div className='w-1/2 flex flex-col gap-4 sm:w-full'>
          <span className="grad-border p-0.5 rounded-2xl overflow-hidden w-[272px] h-9 flex justify-start items-center sm:w-[240px] sm:h-8">
            <h2 className="bg-white uppercase text-blue-950 text-sm font-semibold leading-0 px-4 pt-2 pb-1 rounded-2xl w-[270px] sm:text-xs sm:w-[238px]">
              Your Time. Your Pay. Simplified.
            </h2>
          </span>

          <h1 className='text-black text-[65px] font-bold leading-[75px] sm:text-[45px] sm:leading-[55px]'>Simplify Visa Application Management</h1>
          <p className='text-base text-black font-normal w-[80%] sm:w-full'>Docurize is the all-in-one platform for immigration agents to securely manage client documents, track application progress, and collaborate seamlessly.</p>
          <div className='flex justify-start items-stretch gap-5 mt-2 sm:gap-1'>
            <Link to='/sign-up' className='rounded-lg bg-blue-600 px-6 py-2 text-white font-semibold sm:text-xs sm:px-2 whitespace-nowrap'>
              Sign Up
            </Link>
            <button className='rounded-lg border-[2px] border-blue-600 px-6 py-2 text-blue-600 font-semibold flex justify-center items-center gap-3 sm:text-xs sm:px-3 sm:gap-1 whitespace-nowrap'>
              Watch a Quick Demo
              <IoPlay className='text-blue-600 text-lg' />
            </button>
          </div>

        </div>

        <img src={require(`../assets/Group 1410088420.png`)} className='w-1/2 sm:w-full drop-shadow-xl' />
      </div>

      <div className='px-32 sm:px-0 py-14 sm:py-8'>
        <div className='bg-blue-50 px-10 py-8 rounded-2xl flex flex-col gap-10 sm:px-2 sm:gap-5'>
          <h2 className='text-black text-lg font-bold text-center sm:text-sm'>Trusted by 180,000+ customers world wide</h2>

          <div className='flex justify-between items-center gap-12 sm:justify-around sm:gap-0'>

            <img src={require(`../assets/Frame.png`)} className='w-40 sm:w-24' />
            <img src={require(`../assets/Frame (1).png`)} className='w-44 sm:w-24' />
            <img src={require(`../assets/logo-76.png`)} className='w-48 sm:w-24' />
            <img src={require(`../assets/Frame (2).png`)} className='w-32 sm:hidden' />
            <img src={require(`../assets/Frame (3).png`)} className='w-44 sm:hidden' />
          </div>
        </div>
      </div>


      <div className='flex justify-between items-center pt-14 sm:pt-8 pb-10 px-16 sm:px-5 sm:flex-col sm:gap-4'>

        <div className='w-1/2 flex flex-col gap-4 sm:w-full'>
          <span className="grad-border p-0.5 rounded-2xl overflow-hidden w-[242px] h-9 flex justify-start items-center sm:w-[212px] sm:h-8">
            <h2 className="bg-white uppercase text-blue-950 text-sm font-semibold leading-0 px-4 pt-2 pb-1 rounded-2xl w-[240px] sm:text-xs sm:w-[210px]">
              your immigration journey
            </h2>
          </span>

          <h1 className='text-black text-[55px] font-bold leading-[75px] capitalize sm:text-[45px] sm:leading-[55px]'>Organized Workspace</h1>
          <p className='text-base text-black font-normal w-[80%] sm:w-full'>Say goodbye to disorganized emails and endless spreadsheets. Docurize provides a centralized hub to manage every aspect of your client's visa applications. </p>
          <div className='flex justify-start items-stretch gap-5 mt-2'>
            <button className='rounded-lg bg-blue-600 px-6 py-2 text-white font-semibold flex justify-center items-center gap-3 sm:px-3 sm:text-xs sm:gap-1'>
              Explore Platform
              <IoMdArrowForward className='text-white text-lg' />
            </button>
          </div>

        </div>

        <img src={require(`../assets/Group 1410088352.png`)} className='w-1/2 drop-shadow-xl sm:w-full' />
      </div>

      <div id='about' className='flex justify-between items-center pt-28 pb-20 px-16 sm:pb-10 gap-12 sm:flex-col-reverse sm:px-5 sm:gap-4 sm:pt-10'>

        <img src={require(`../assets/Rectangle 3464168.png`)} className='w-[45%] drop-shadow-xl sm:w-full' />
        <div className='w-1/2 flex flex-col gap-4 sm:w-full'>
          <span className="grad-border p-0.5 rounded-2xl overflow-hidden w-[242px] h-9 flex justify-start items-center sm:w-[190px] sm:h-8">
            <h2 className="bg-white uppercase text-blue-950 text-sm font-semibold leading-0 px-4 pt-2 pb-1 rounded-2xl w-[240px] sm:text-xs sm:w-[188px]">
              organized workspace
            </h2>
          </span>

          <h1 className='text-black text-[60px] font-bold leading-[75px] capitalize sm:text-[40px] sm:leading-[50px]'>Securely manage your<br className='sm:hidden' /> <span className='font-light'>clients' immigration </span> applications</h1>
          <p className='text-base text-black font-normal w-[80%] sm:w-full'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi in porttitor quam, id ornare leo. Aliquam erat volutpat. Proin egestas iaculis commodo. </p>
          <div className='flex justify-start items-stretch gap-5 mt-2'>
            <button className='rounded-lg bg-blue-600 px-6 py-2 text-white font-semibold flex justify-center items-center gap-3 sm:gap-1 sm:px-3 sm:text-xs'>
              Explore Platform
              <IoMdArrowForward className='text-white text-lg' />
            </button>
          </div>

        </div>

      </div>

      <div id='pricing' className='flex flex-col gap-2 items-center justify-center px-32 pt-20 pb-20 sm:pb-10 sm:pt-4 mt-4 sm:px-4 sm:flex-col'>
        <span className="grad-border p-0.5 rounded-2xl overflow-hidden w-[212px] h-9 flex justify-start items-center sm:w-[190px] sm:h-8">
          <h2 className="bg-white uppercase text-blue-950 text-sm font-semibold leading-0 px-4 pt-2 pb-1 rounded-2xl w-[210px] sm:text-xs sm:w-[188px]">
            organized workspace
          </h2>
        </span>

        <h1 className='capitalize text-black font-bold text-[65px] sm:text-[40px] sm:leading-[50px] text-center'>our Pricing Plan</h1>

        <div className='flex justify-start items-stretch gap-5 w-full mt-4 sm:flex-col'>
          <div className='flex-1 flex-col flex gap-3 border-[1px] border-grey-50 rounded-lg p-4'>
            <img src={require(`../assets/Group 1171278752.png`)} className='w-8' />

            <h2 className='text-black text-lg font-bold'>Forever Sophia</h2>
            <h2 className='text-black text-3xl font-bold'>Free</h2>

            <p className='text-sm text-[#475569] font-normal'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vel fringilla nulla, ut luctus nunc.</p>

            <button className='rounded-lg border-[2px] border-grey-50 px-6 py-2 text-black bg-grey-100 font-bold flex justify-center items-center gap-3'>
              Current Plan
            </button>
            <h2 className='text-black text-lg font-bold'>Features</h2>
            <p className='text-sm text-[#475569] font-normal -mt-3'>Everything in our free plan...</p>

            <ul className='flex flex-col gap-3 mt-3'>
              <li className='flex gap-2 justify-start'>
                <span className='flex p-1 rounded-full justify-center items-center bg-[#BEF264]'>
                  <FaCheck className='text-black text-sm' />
                </span>
                <h2 className='text-black text-base font-semibold sm:text-sm'>Lorem ipsum dolor sit amet</h2>
              </li>
              <li className='flex gap-2 justify-start'>
                <span className='flex p-1 rounded-full justify-center items-center bg-[#BEF264]'>
                  <FaCheck className='text-black text-sm' />
                </span>
                <h2 className='text-black text-base font-semibold sm:text-sm'>Lorem ipsum dolor sit amet</h2>
              </li>
              <li className='flex gap-2 justify-start'>
                <span className='flex p-1 rounded-full justify-center items-center bg-[#BEF264]'>
                  <FaCheck className='text-black text-sm' />
                </span>
                <h2 className='text-black text-base font-semibold sm:text-sm'>Lorem ipsum dolor sit amet</h2>
              </li>
              <li className='flex gap-2 justify-start'>
                <span className='flex p-1 rounded-full justify-center items-center bg-[#BEF264]'>
                  <FaCheck className='text-black text-sm' />
                </span>
                <h2 className='text-black text-base font-semibold sm:text-sm'>Lorem ipsum dolor sit amet</h2>
              </li>
            </ul>

          </div>

          <div className='flex-1 bg-blue-600  flex-col flex gap-3 rounded-lg p-4'>
            <div className='flex justify-between items-center'>
              <img src={require(`../assets/Group 1171278753.png`)} className='w-8' />
              <span className='bg-white/30 border-[1px] border-white p-1 px-2 rounded-md text-white font-bold text-sm'>Recommended</span>
            </div>

            <h2 className='text-white text-lg font-bold'>Business</h2>
            <h2 className='text-white text-3xl font-normal'>$ <b>39</b>.99 <span className='text-sm'>Per Month</span></h2>

            <p className='text-sm text-white font-normal'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vel fringilla nulla, ut luctus nunc.</p>

            <button className='rounded-lg border-[2px] border-white px-6 py-2 text-blue-600 bg-white font-bold flex justify-center items-center gap-3'>
              Buy Now
            </button>
            <h2 className='text-white text-lg font-bold'>Features</h2>
            <p className='text-sm text-white font-normal -mt-3'>Everything in our free plan...</p>

            <ul className='flex flex-col gap-3 mt-3'>
              <li className='flex gap-2 justify-start'>
                <span className='flex p-1 rounded-full justify-center items-center bg-white'>
                  <FaCheck className='text-black text-sm' />
                </span>
                <h2 className='text-white text-base font-medium sm:text-sm'>Lorem ipsum dolor sit amet</h2>
              </li>
              <li className='flex gap-2 justify-start'>
                <span className='flex p-1 rounded-full justify-center items-center bg-white'>
                  <FaCheck className='text-black text-sm' />
                </span>
                <h2 className='text-white text-base font-medium sm:text-sm'>Lorem ipsum dolor sit amet</h2>
              </li>
              <li className='flex gap-2 justify-start'>
                <span className='flex p-1 rounded-full justify-center items-center bg-white'>
                  <FaCheck className='text-black text-sm' />
                </span>
                <h2 className='text-white text-base font-medium sm:text-sm'>Lorem ipsum dolor sit amet</h2>
              </li>
              <li className='flex gap-2 justify-start'>
                <span className='flex p-1 rounded-full justify-center items-center bg-white'>
                  <FaCheck className='text-black text-sm' />
                </span>
                <h2 className='text-white text-base font-medium sm:text-sm'>Lorem ipsum dolor sit amet</h2>
              </li>
            </ul>
          </div>

          <div className='flex-1 flex-col flex gap-3 border-[1px] border-grey-50 rounded-lg p-4'>
            <img src={require(`../assets/Group 1171278752.png`)} className='w-8' />

            <h2 className='text-black text-lg font-bold'>Business</h2>
            <h2 className='text-blue-600 text-3xl font-normal'>$ <b>19</b>.99 <span className='text-sm'>Per Month</span></h2>

            <p className='text-sm text-[#475569] font-normal'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vel fringilla nulla, ut luctus nunc.</p>

            <button className='rounded-lg border-[2px] border-blue-600 px-6 py-2 text-white bg-blue-600 font-bold flex justify-center items-center gap-3'>
              Buy Now
            </button>
            <h2 className='text-black text-lg font-bold'>Features</h2>
            <p className='text-sm text-[#475569] font-normal -mt-3'>Everything in our free plan...</p>

            <ul className='flex flex-col gap-3 mt-3'>
              <li className='flex gap-2 justify-start'>
                <span className='flex p-1 rounded-full justify-center items-center bg-[#BEF264]'>
                  <FaCheck className='text-black text-sm' />
                </span>
                <h2 className='text-black text-base font-semibold sm:text-sm'>Lorem ipsum dolor sit amet</h2>
              </li>
              <li className='flex gap-2 justify-start'>
                <span className='flex p-1 rounded-full justify-center items-center bg-[#BEF264]'>
                  <FaCheck className='text-black text-sm' />
                </span>
                <h2 className='text-black text-base font-semibold sm:text-sm'>Lorem ipsum dolor sit amet</h2>
              </li>
              <li className='flex gap-2 justify-start'>
                <span className='flex p-1 rounded-full justify-center items-center bg-[#BEF264]'>
                  <FaCheck className='text-black text-sm' />
                </span>
                <h2 className='text-black text-base font-semibold sm:text-sm'>Lorem ipsum dolor sit amet</h2>
              </li>
              <li className='flex gap-2 justify-start'>
                <span className='flex p-1 rounded-full justify-center items-center bg-[#BEF264]'>
                  <FaCheck className='text-black text-sm' />
                </span>
                <h2 className='text-black text-base font-semibold sm:text-sm'>Lorem ipsum dolor sit amet</h2>
              </li>
            </ul>

          </div>
        </div>
      </div>



      <Footer />
    </div>
  );
}

export default Landing;
