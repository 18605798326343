import axios from 'axios';
import { IApplicant } from '../types'; // Adjust path to where the types are defined
import { BASE_SERVER } from '../constants';
import { getToken } from '../utils/getToken';


export const getApplicants = async (): Promise<IApplicant> => {
    const response = await axios.get<IApplicant>(`${BASE_SERVER}/applicants/`, {
        headers: {
            'Authorization': `Bearer ${getToken()}`,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
    });
    return response.data;
};
