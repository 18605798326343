import axios, { AxiosResponse } from "axios";
import { BASE_SERVER } from "../constants";
import { getToken } from "../utils/getToken";

interface UpdatePasswordRequest {
    current_password: string;
    new_password: string;
}

interface FailureResponse {
    detail?: string;
}

export const updatePassword = async (
    current_password: string,
    new_password: string
): Promise<AxiosResponse<FailureResponse | any>> => {
    const data: UpdatePasswordRequest = {
        current_password,
        new_password
    };

    try {
        const response: AxiosResponse<any> = await axios.patch(`${BASE_SERVER}/users/me/password`, data, {
            headers: {
                'Authorization': `Bearer ${getToken()}`,
                'Content-Type': 'application/json',
            },
        });
        return response;
    } catch (error: any) {
        if (error.response) {
            return error.response;
        } else if (error.request) {
            return {
                data: { detail: "No response from server" },
                status: 0,
                statusText: '',
                headers: {},
                config: {},
                request: error.request
            } as AxiosResponse<FailureResponse>;
        } else {
            return {
                data: { detail: error.message },
                status: 0,
                statusText: '',
                headers: {},
                config: {},
                request: null
            } as AxiosResponse<FailureResponse>;
        }
    }
};
