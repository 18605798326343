import axios, { AxiosResponse } from "axios";
import { BASE_SERVER } from "../constants";

interface Signup {
    email: string;
    password: string;
    first_name: string;
    last_name: string;
    full_name: string;
}

interface FailureResponse {
    detail?: string;
}

export const registerUser = async (
    email: string, 
    password: string, 
    first_name: string, 
    last_name: string, 
    full_name: string
): Promise<AxiosResponse<FailureResponse>> => {
    const data: Signup = {
        email,
        password,
        first_name,
        last_name,
        full_name
    };
    
    try {
        const response: AxiosResponse<FailureResponse> = await axios.post(`${BASE_SERVER}/users/signup`, data, {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
            },
        });
        return response;
    } catch (error: any) {
        if (error.response) {
            // Server responded with a status code other than 2xx
            return error.response;
        } else if (error.request) {
            // Request was made but no response was received
            return {
                data: { detail: "No response from server" },
                status: 0,
                statusText: '',
                headers: {},
                config: {},
                request: error.request
            } as AxiosResponse<FailureResponse>;
        } else {
            // Something else happened while setting up the request
            return {
                data: { detail: error.message },
                status: 0,
                statusText: '',
                headers: {},
                config: {},
                request: null
            } as AxiosResponse<FailureResponse>;
        }
    }
}
