import axios from 'axios';
import { Checklist } from '../types'; // Adjust path to where the types are defined
import { BASE_SERVER } from '../constants';
import { getToken } from '../utils/getToken';


export const getDocs = async (id:number): Promise<any> => {
    try {
        const response = await axios.get<Checklist>(`${BASE_SERVER}/documents/${id}/access_file`, {
            headers: {
                'Authorization': `Bearer ${getToken()}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        // Handle errors or re-throw for the caller to handle
        throw error;
    }
};
