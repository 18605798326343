import React, { useEffect, useState } from 'react'
import Input from '../components/Input'
import { GOOGLE_CLIENT_ID, G_REDIRECT_URL } from '../constants';
import { login } from '../services/login';
import { Link, useNavigate } from 'react-router-dom';
import { registerUser } from '../services/registerUser';
import { toast } from 'react-toastify';
import { getUserProfile } from '../services/getUserProfile';
import FacebookLogin from '@greatsumini/react-facebook-login';

interface login {
    email: string;
    password: string;
    f_name: string;
    l_name: string;
}

const Login: React.FC = () => {
    const [logins, setLogins] = useState<login>({
        email: '',
        password: '',
        f_name: '',
        l_name: ''
    })
    const navigate = useNavigate()
    const [loading, setLoading] = useState<boolean>(false)

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLogins({ ...logins, [e.target.name]: e.target.value })
    }

    const handleGoogleLogin = () => {
        const authUrl = `https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=${GOOGLE_CLIENT_ID}&redirect_uri=${encodeURIComponent(G_REDIRECT_URL)}&scope=email%20profile&prompt=consent`;
        window.location.href = authUrl;
    };

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);

        let response;
        const pathname = window.location.pathname;

        if (pathname === '/login') {
            response = await login(logins.email, logins.password);

            if (response.status === 200 && 'access_token' in response.data) {
                localStorage.setItem('token', response.data.access_token || '');
                const data = await getUserProfile();
                localStorage.setItem('user', JSON.stringify(data))
                window.location.pathname = '/';
            } else {
                toast.error(response.data.detail || 'Login failed. Please try again.');
            }
        } else {
            response = await registerUser(logins.email, logins.password, logins.f_name, logins.l_name, logins.f_name + ' ' + logins.l_name);

            if (response.status === 200) {
                (document.getElementById('f_name') as HTMLInputElement).value = '';
                (document.getElementById('l_name') as HTMLInputElement).value = '';
                (document.getElementById('email') as HTMLInputElement).value = '';
                (document.getElementById('password') as HTMLInputElement).value = '';
                setLogins({
                    f_name: '',
                    l_name: '',
                    email: '',
                    password: ''
                })
                toast.success('Account created successfully');
                navigate('/login');
            } else {
                toast.error(response.data.detail || 'Registration failed. Please try again.');
            }
        }

        setLoading(false);
    };


    const handleLoginOrSignup = (response: any) => {
        const { email, name, id } = response;
        setLogins({ ...logins, email: email, f_name: name.split(' ')[0], l_name: name.split(' ')[1], password: id });
        const event = new Event('submit', {
            bubbles: true,
            cancelable: true,
        });
        document.getElementById('loginForm')?.dispatchEvent(event)
    };


    useEffect(() => {
        localStorage.setItem('lastPath', window.location.pathname)
    }, [window.location.pathname])

    return (

        <div className='flex flex-1 h-screen overflow-hidden justify-end sm:flex-col'>
            <div className='w-[60%] absolute top-0 left-0 h-screen overflow-hidden sm:hidden'>
                <img src={require('../assets/Rectangle 4530.png')} className='w-[90%] h-screen object-cover blur-sm' />
                {/* <h2 className='text-3xl font-bold text-white absolute left-14 bottom-8 w-[75%] z-[100]'>"Feeling overwhelmed by paperwork? Simplify your workflow and manage client applications with ease. Log in and experience the future of efficient immigration processing."</h2> */}
            </div>

            <div className='absolute top-0 left-0 w-full h-screen z-40 sm:hidden' style={{ background: "linear-gradient(90deg, rgba(0, 0, 0, 0.725) 20%, rgb(255 255 255 / 99%) 52%)" }}>
            </div>

            <div className='flex justify-center items-center flex-col w-[40%] pl-0 pr-32 overflow-scroll h-auto pt-32  sm:w-full sm:px-8 sm:pt-40 z-50'>
                <img src={require('../assets/logo.png')} className={`w-[250px] sm:w-[188px] cursor-pointer mt-28 ${window.location.pathname === '/login' ? 'sm:-mt-0' : 'sm:mt-16'}`} />
                <h2 className='text-black font-bold text-xl text-center mt-6'>{window.location.pathname === '/login' ? 'Welcome Back' : 'Create Account'}</h2>
                <h2 className='text-[#475569] font-medium text-sm text-center mt-1 sm:text-xs'>Enter your email & password to access your account</h2>

                <form id='loginForm' onSubmit={onSubmit} className='w-full flex flex-col gap-4 mt-8'>
                    {window.location.pathname === '/sign-up' && <div className='flex gap-4 sm:flex-col'>
                        <Input type='text' onChange={onChange} value='' placeholder='Enter your first name' required={true} disabled={false} label={"First Name"} name='f_name' readOnly={false} />
                        <Input type='text' onChange={onChange} value='' placeholder='Enter your last name' required={true} disabled={false} label={"Last Name"} name='l_name' readOnly={false} />

                    </div>}
                    <Input type='email' onChange={onChange} value='' placeholder='Enter your email' required={true} disabled={false} label={"Email"} name='email' readOnly={false} />
                    <Input type='password' onChange={onChange} value='' placeholder='Enter your password' required={true} disabled={false} label={"Password"} name='password' readOnly={false} />
                    {window.location.pathname === '/login' && <div className='flex justify-between items-center'>
                        <span className='flex gap-2'>
                            <input type='checkbox' className='' />
                            <p className='text-[#3D3D3D] font-medium text-sm sm:text-xs'>Remember me</p>
                        </span>
                        <p className='text-[#3D3D3D] font-medium text-sm cursor-pointer sm:text-xs'>Forgot Password?</p>
                    </div>}
                    <button disabled={loading} className='disabled:opacity-75 disabled:cursor-wait rounded-3xl border-[2px] border-blue-600 px-6 py-3 sm:py-2.5 sm:text-xs mt-3 text-white bg-blue-600 font-bold flex justify-center items-center gap-3'>
                        {window.location.pathname === '/login' ? 'Login' : 'Sign Up'}
                    </button>
                </form>

                <p className='text-[#3E3E3E] text-base font-medium mt-6 flex gap-1  sm:text-xs'>
                    {window.location.pathname === '/login' ?
                        <>
                            Don't have an account?
                            <Link to='/sign-up' className='text-blue-600 underline font-bold cursor-pointer'>
                                Sign Up
                            </Link>
                        </>
                        :
                        <>
                            Already have an account?
                            <Link to='/login' className='text-blue-600 underline font-bold cursor-pointer'>
                                Login
                            </Link>
                        </>
                    }
                </p>
                <p className='text-[#3E3E3E] text-base font-medium mt-8 flex gap-1  sm:text-xs'>
                    {window.location.pathname === '/login' ? ' Or Login with' : ' Or Sign Up with'}
                </p>


                <button onClick={handleGoogleLogin} className='rounded-3xl border-[2px] border-grey-50 px-6 py-3 text-black bg-white font-normal flex justify-center items-center gap-3 w-full mt-8  sm:text-xs sm:py-2.5'>
                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21.8055 10.1072H21V10.0657H12V14.0657H17.6515C16.827 16.3942 14.6115 18.0657 12 18.0657C8.6865 18.0657 6 15.3792 6 12.0657C6 8.75223 8.6865 6.06573 12 6.06573C13.5295 6.06573 14.921 6.64273 15.9805 7.58523L18.809 4.75673C17.023 3.09223 14.634 2.06573 12 2.06573C6.4775 2.06573 2 6.54323 2 12.0657C2 17.5882 6.4775 22.0657 12 22.0657C17.5225 22.0657 22 17.5882 22 12.0657C22 11.3952 21.931 10.7407 21.8055 10.1072Z" fill="#FFC107" />
                        <path d="M3.15295 7.41123L6.43845 9.82073C7.32745 7.61973 9.48045 6.06573 12 6.06573C13.5295 6.06573 14.921 6.64273 15.9805 7.58523L18.809 4.75673C17.023 3.09223 14.634 2.06573 12 2.06573C8.15895 2.06573 4.82795 4.23423 3.15295 7.41123Z" fill="#FF3D00" />
                        <path d="M12 22.0657C14.583 22.0657 16.93 21.0772 18.7045 19.4697L15.6095 16.8507C14.5718 17.6399 13.3038 18.0668 12 18.0657C9.39903 18.0657 7.19053 16.4072 6.35853 14.0927L3.09753 16.6052C4.75253 19.8437 8.11353 22.0657 12 22.0657Z" fill="#4CAF50" />
                        <path d="M21.8055 10.1072H21V10.0657H12V14.0657H17.6515C17.2571 15.1739 16.5467 16.1423 15.608 16.8512L15.6095 16.8502L18.7045 19.4692C18.4855 19.6682 22 17.0657 22 12.0657C22 11.3952 21.931 10.7407 21.8055 10.1072Z" fill="#1976D2" />
                    </svg>

                    <span>
                        Continue with <b>Google</b>
                    </span>
                </button>
            </div>

        </div>
    )
}

export default Login
