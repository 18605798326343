import React, { useContext, useEffect, useState } from 'react'
import { IoArrowBack } from 'react-icons/io5'
import { useNavigate, useParams } from 'react-router-dom'
import Input from '../components/Input'
import Select from 'react-select'
import { CountryDropdown } from 'react-country-region-selector';
import { createApplication } from '../services/createApplication'
import { toast } from 'react-toastify'
import { getSingleApplication } from '../services/getSingleApplication'
import { updateApplication } from '../services/updateApplication'
import { Context } from '../state/Provider'
import { GoTrash } from 'react-icons/go'

interface Applicant {
    first_name: string;
    last_name: string;
    email: string;
    full_name: string;
}

interface Application {
    name: string;
    description: string;
    country_of_application: string;
    type_of_application: string;
    applicants: Applicant[];
}

interface Application {
    id?: number;
}

const Application: React.FC = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const { setRefreshApplications, setRefreshApplicants, applicants } = useContext(Context)
    const [application, setApplication] = useState<Application>({
        name: '',
        description: '',
        country_of_application: '',
        type_of_application: '',
        applicants: [{ first_name: '', last_name: '', email: '', full_name: '' }]
    });
    const [newApplicant, setNewApplicant] = React.useState<boolean>(true)
    const [loading, setLoading] = React.useState<boolean>(false)

    const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setApplication({ ...application, [e.target.name]: e.target.value })
    }

    const handleApplicantChange = (index: number, e: React.ChangeEvent<HTMLSelectElement>) => {
        if (e.target.name === 'existing') {
            const existingApplicant = applicants?.data.find((app: any) => app.id === parseInt(e.target.value))
            if (existingApplicant) {
                const mappedApplicant: Applicant = {
                    first_name: existingApplicant.first_name ?? '',
                    last_name: existingApplicant.last_name ?? '',
                    email: existingApplicant.email ?? '',
                    full_name: `${existingApplicant.first_name ?? ''} ${existingApplicant.last_name ?? ''}`
                    // Include other fields if necessary, ensuring they have valid values
                };
    
                setApplication((prevState: Application): Application => {
                    const newApplicants = prevState.applicants.map((applicant, i) =>
                        i === index ? mappedApplicant : applicant
                    );
                    return { ...prevState, applicants: newApplicants };
                });
            }
        }
        else
            setApplication(prevState => {
                const newApplicants = prevState.applicants.map((applicant, i) =>
                    i === index ? { ...applicant, [e.target.name]: e.target.value, full_name: applicant.first_name + ' ' + applicant.last_name } : applicant
                );
                return { ...prevState, applicants: newApplicants };
            });
    };

    const addApplicant = () => {
        setApplication(prevState => ({
            ...prevState,
            applicants: [
                ...prevState.applicants,
                { first_name: '', last_name: '', email: '', full_name: '' }
            ]
        }));
    };

    const removeApplicant = (index: number) => {
        setApplication(prevState => ({
            ...prevState,
            applicants: prevState.applicants.filter((_, i) => i !== index)
        }));
    };

    const onsubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setLoading(true)
        try {
            let response

            if (id)
                response = await updateApplication(id, application)
            else
                response = await createApplication(application)
            if (response.status === 200 || response.status === 201) {
                if (id)
                    toast.success('Application Updated')
                else
                    toast.success('Application Created')

                setRefreshApplications(prevState => !prevState)
                setRefreshApplicants(prevState => !prevState)
                navigate(-1)
            }
            else
                toast.error(response.data.detail)


        } catch (error) {
            console.log(error);
        }
        setLoading(false)
    }

    const getApp = async () => {
        if (id)
            try {
                const response: Application = await getSingleApplication(id)
                setApplication(response)
            } catch (error) {
                console.log(error);
            }

    }


    useEffect(() => {
        getApp()
    }, [id])
    return (
        <div className='flex flex-col gap-4 justify-start items-start py-10 px-8 sm:px-4'>
            <button onClick={() => navigate(-1)} className='flex items-center gap-1 bg-grey-50 rounded-3xl px-2 py-2 text-black cursor-pointer text-sm sm:text-xs'>
                <IoArrowBack className='text-black text-md' />
                Back
            </button>
            <div>
                <h1 className="text-black font-bold text-lg flex gap-2 items-center capitalize">{id ? 'Edit' : 'New'} Application</h1>
                <p className="text-sm text-stalle-700 font-medium  sm:text-xs">You are {id ? 'updating' : 'creating'} new application</p>
            </div>

            <form onSubmit={onsubmit} className='flex flex-col gap-4 mt-2 justify-start items-start w-full'>
                <div className='flex gap-3 w-full sm:flex-col' >
                    <Input type='text' onChange={onChange} value={application.name} placeholder='Type here' required={true} disabled={false} label={"Name"} name='name' readOnly={false} />
                    <div className='flex flex-col gap-1 relative w-full'>
                        <label className='text-black text-sm font-bold sm:text-xs'>Select Country</label>
                        <CountryDropdown classes="border-[2px] border-grey-50/75 focus:border-black/50 transition-all ease-in duration-150 rounded-lg px-3 py-0 h-[56px] font-medium placeholder:text-black/50 text-black text-sm outline-none sm:text-xs sm:px-4 sm:h-12"
                            value={application.country_of_application}
                            onChange={(val) => { setApplication({ ...application, country_of_application: val }) }} />
                    </div>
                    <div className='flex flex-col gap-1 relative w-full'>
                        <label className='text-black text-sm font-bold sm:text-xs'>Select Type</label>
                        <select required name='type_of_application' onChange={(e) => {
                            onChange(e)
                        }} className="border-[2px] border-grey-50/75 focus:border-black/50 transition-all ease-in duration-150 rounded-lg px-3 py-0 h-[56px] font-medium placeholder:text-black/50 text-black text-sm outline-none  sm:text-xs sm:px-4  sm:h-12">
                            <option value={''}>Select Type</option>
                            <option selected={application.type_of_application === 'visitor' && true} value={'visitor'}>Visitor</option>
                            <option selected={application.type_of_application === 'student' && true} value={'student'}>Student</option>
                            <option selected={application.type_of_application === 'work' && true} value={'work'}>Work</option>
                            <option selected={application.type_of_application === 'other' && true} value={'other'}>Other</option>
                        </select>
                    </div>
                </div>

                <h1 className="text-black font-bold text-base mt-2 -mb-2">Applicants</h1>
                <div className='flex flex-col'>
                    <div className='flex flex-col gap-1'>
                        <span className='flex gap-1 items-center text-black text-sm font-medium sm:text-xs'>
                            <input type='radio' name='hello' checked={!newApplicant} onChange={() => setNewApplicant(false)} /> Existing Applicants
                        </span>
                        <span className='flex gap-1 items-center text-black text-sm font-medium sm:text-xs'>
                            <input type='radio' name='hello' checked={newApplicant} onChange={() => setNewApplicant(true)} /> New Applicant
                        </span>
                    </div>
                </div>
                {application.applicants.map((_, index) => (
                    <>
                        {index > 0 && <hr className='w-full h-[1px] opacity-25 bg-black/5' />}
                        {newApplicant ? <div key={index} className='flex gap-3 w-full relative sm:flex-col'>
                            <Input type='text' onChange={(e: any) => { handleApplicantChange(index, e) }} value={application?.applicants[index]?.first_name || ''} placeholder='Type here' required={true} disabled={false} label={"First Name"} name='first_name' readOnly={false} />
                            <Input type='text' onChange={(e: any) => { handleApplicantChange(index, e) }} value={application?.applicants[index]?.last_name || ''} placeholder='Type here' required={true} disabled={false} label={"Last Name"} name='last_name' readOnly={false} />
                            <Input type='email' onChange={(e: any) => { handleApplicantChange(index, e) }} value={application?.applicants[index]?.email || ''} placeholder='Type here' required={true} disabled={false} label={"Email"} name='email' readOnly={false} />
                            {application.applicants.length > 1 && <GoTrash onClick={() => removeApplicant(index)} className='absolute top-0 right-0 text-red text-lg cursor-pointer sm:text-base sm:-top-1' />}
                        </div>
                            :
                            <div className='flex flex-col gap-1 relative w-1/3 sm:w-full'>
                                <label className='text-black text-sm font-bold sm:text-xs'>Select Applicant</label>
                                <select required name='existing' onChange={(e: any) => { handleApplicantChange(index, e) }} className="border-[2px] border-grey-50/75 focus:border-black/50 transition-all ease-in duration-150 rounded-lg px-3 py-0 h-[56px] font-medium placeholder:text-black/50 text-black text-sm outline-none sm:text-xs sm:px-4  sm:h-12">
                                    <option value={''}>Select</option>
                                    {applicants?.data?.map((app: any, index: number) => (
                                        <option key={index} value={app.id}>{app.full_name}</option>
                                    ))}
                                </select>
                                {application.applicants.length > 1 && <GoTrash onClick={() => removeApplicant(index)} className='absolute top-0 right-0 text-red text-lg cursor-pointer sm:text-base sm:-top-1' />}
                            </div>
                        }
                    </>
                ))}
                <p onClick={addApplicant}
                    className='text-blue-600 font-semibold text-right cursor-pointer text-xs self-end'
                >Add Applicant</p>

                <button disabled={loading} className='disabled:opacity-75 -mt-8 disabled:cursor-wait rounded-3xl border-[2px] border-blue-600 px-6 py-3 sm:px-3 sm:py-2 sm:text-xs text-white bg-blue-600 font-bold flex text-sm justify-center items-center gap-3'>
                    {id ? 'Update' : 'Create'}
                </button>
            </form>
        </div>
    )
}

export default Application