import React, { useContext, useEffect, useState } from "react"
import { GoFilter, GoPlus, GoTrash } from 'react-icons/go'
import { Link, useNavigate, useParams } from "react-router-dom"
import { BsStars } from 'react-icons/bs'
import { CiSearch } from 'react-icons/ci'
import { getApplications } from "../services/getApplications"
import { Application, IApplications } from "../types"
import { BiSolidPencil } from "react-icons/bi";
import { IoEyeOutline } from "react-icons/io5"
import { deleteApplication } from "../services/deleteApplication"
import { toast } from "react-toastify"
import { Context } from "../state/Provider"
import { RxCross1, RxCross2 } from "react-icons/rx"
import { HiOutlineDotsVertical } from "react-icons/hi"
import MobileViewApplication from "../components/MobileViewApplication"

const Applications: React.FC = () => {
    const { applications, setRefreshApplications, setApplicationDetailSideBar } = useContext(Context)
    const [loading, setLoading] = useState<boolean>(false)
    const [filterPopup, setFilterPopup] = useState<boolean>(false)
    const [refresh, setRefresh] = useState<boolean>(false)
    const navigate = useNavigate()

    const [localApplications, setLocalApplication] = useState<Application[] | null>(applications?.data || null)

    const deleteApp = async (id: string) => {
        const confirm = window.confirm('Are you sure you want to delete this application?')
        if (!confirm) return
        setLoading(true)
        try {
            const response = await deleteApplication(id)
            if (response.status === 204) {
                toast.success("Application deleted.")
                setRefreshApplications(prevState => !prevState)
            }
            else
                toast.error(response.data.detail)
        } catch (error) {
            console.error(error);
        }
        setLoading(false)
    }

    const searchApplications = (e: React.ChangeEvent<HTMLInputElement>) => {
        const search = e.target.value
        if (search === '') {
            setLocalApplication(applications?.data ?? null)
        } else {
            const filteredApplications = applications?.data?.filter(application => application.name.toLowerCase().includes(search.toLowerCase()))
            setLocalApplication(filteredApplications ?? null)
        }
    }
    useEffect(() => {
        setLocalApplication(applications?.data || null)

        // return () => {
        //     localStorage.removeItem('type_of_application_filter')
        //     localStorage.removeItem('current_stage_filter')
        //     localStorage.removeItem('current_status_filter')
        // }
    }, [applications])


    const filter = () => {
        const type_of_application = localStorage.getItem('type_of_application_filter')
        const current_stage = localStorage.getItem('current_stage_filter')
        const current_status = localStorage.getItem('current_status_filter')

        setLocalApplication(applications?.data?.filter(application => {
            if (type_of_application && current_stage && current_status && current_stage?.length > 0 && current_status?.length > 0) {
                return application.type_of_application === type_of_application && application.current_stage === current_stage && application.current_status === current_status
            }
            else if (type_of_application && current_stage && current_stage?.length > 0) {
                return application.type_of_application === type_of_application && application.current_stage === current_stage
            }
            else if (type_of_application && current_status && current_status?.length > 0) {
                return application.type_of_application === type_of_application && application.current_status === current_status
            }
            else if (current_stage && current_status && current_stage?.length > 0 && current_status?.length > 0) {
                return application.current_stage === current_stage && application.current_status === current_status
            }
            else if (type_of_application && type_of_application?.length > 0) {
                return application.type_of_application === type_of_application
            }
            else if (current_stage && current_stage?.length > 0) {
                return application.current_stage === current_stage
            }
            else if (current_status && current_status?.length > 0) {
                return application.current_status === current_status
            }
            else {
                return application
            }
        }
        ))

    }

    useEffect(() => {
        filter()
    }, [filterPopup, refresh])


    return (
        <div className="py-10 px-8 sm:px-4">
            <div className="flex justify-between items-center sm:flex-col sm:gap-3 sm:justify-start sm:items-start">
                <div>
                    <h1 className="text-black font-bold text-2xl flex gap-2 items-center capitalize">Welcome, {JSON.parse(localStorage.getItem('user') || "{}").first_name}
                        <img src={require('../assets/image 6.png')} />
                    </h1>
                    <p className="text-sm text-stalle-700 font-medium mt-1 sm:text-xs">Here's what's happening with your applications today</p>
                </div>
                <div className="flex gap-2 sm:justify-start">

                    <Link to='create' className="rounded-3xl bg-[#6C6DB3] px-3 py-3 text-white font-semibold flex justify-center items-center capitalize gap-1 sm:text-xs sm:py-2">
                        <GoPlus className='text-white text-xl' />
                        create new application
                    </Link>
                    <Link to='create' className="rounded-3xl bg-blue-600 px-3 py-3 text-white font-semibold flex justify-center items-center capitalize gap-1 sm:text-xs sm:py-2">
                        <BsStars className='text-white text-xl rotate-180' />
                        Start with AI
                    </Link>
                </div>
            </div>



            <div className="border-[1px] border-grey-50 rounded-2xl mt-10 sm:border-none">
                <div className="flex justify-between items-start px-4 py-5 sm:flex-col sm:items-start sm:gap-1.5 sm:p-0">
                    <div>
                        <h1 className="text-black font-bold text-lg flex gap-2 items-center capitalize sm:text-base">Applications</h1>
                        <p className="text-sm text-stalle-700 font-medium sm:text-xs">You have currently <b>{applications.count}</b> applications</p>
                    </div>

                    <div className="flex flex-col gap-1 sm:w-full">
                        <div className="flex gap-2 sm:w-full">
                            <div className='bg-grey-50/40 rounded-3xl w-[300px] border-[1px] border-grey-100 flex items-center gap-1 px-4 p-3 sm:px-3 sm:py-2 sm:w-full '>
                                <CiSearch className='text-black text-xl' />
                                <input onChange={searchApplications} type='text' placeholder='Search for anything' className='w-full font-medium placeholder:text-black/75 text-black/75 h-full text-sm bg-transparent outline-none sm:text-xs' />
                            </div>
                            <div onClick={() => setFilterPopup(true)} className='bg-grey-50/40 rounded-3xl border-[1px] border-grey-100 flex items-center gap-1 px-4 p-3 cursor-pointer sm:p-2'>
                                <GoFilter className='text-black text-xl' />
                                <h1 className="text-black/75 font-medium text-sm sm:hidden">Filters</h1>
                            </div>
                        </div>
                        <div className="flex justify-end gap-1 sm:justify-start sm:mt-1">
                            {localStorage.getItem('type_of_application_filter') && <span className="bg-grey-50/40 rounded-xl font-medium p-1 px-3 sm:px-2 text-black capitalize sm:text-xs flex text-sm items-center gap-1">
                                <RxCross1 className=' text-sm text-red cursor-pointer' onClick={() => { localStorage.removeItem('type_of_application_filter'); setRefresh(s => !s) }} />
                                {localStorage.getItem('type_of_application_filter')}
                            </span>}
                            {localStorage.getItem('current_stage_filter') && <span className="bg-grey-50/40 rounded-xl font-medium p-1 px-3 sm:px-2 text-black capitalize sm:text-xs flex text-sm items-center gap-1">
                                <RxCross1 className=' text-sm text-red cursor-pointer' onClick={() => { localStorage.removeItem('current_stage_filter'); setRefresh(s => !s) }} />
                                {localStorage.getItem('current_stage_filter')}
                            </span>}
                            {localStorage.getItem('current_status_filter') && <span className="bg-grey-50/40 rounded-xl font-medium p-1 px-3 sm:px-2 text-black capitalize sm:text-xs flex text-sm items-center gap-1">
                                <RxCross1 className=' text-sm text-red cursor-pointer' onClick={() => { localStorage.removeItem('current_status_filter'); setRefresh(s => !s) }} />
                                {localStorage.getItem('current_status_filter')}
                            </span>}
                        </div>
                    </div>
                </div>

                <div className="overflow-x-auto sm:hidden">
                    <table className="min-w-full lg:table">
                        <thead className="bg-[#F1F5F9]">
                            <tr>
                                {/* <th className="px-6 py-3 text-left text-sm text-black font-medium text-gray-500 capitalize tracking-wider flex items-center">
                                    <input type='checkbox' className="mr-2" /> Sr.No
                                </th> */}
                                <th className="px-6 py-3 text-left text-sm text-black font-bold text-gray-500 capitalize tracking-wider">Name</th>
                                <th className="px-6 py-3 text-left text-sm text-black font-bold text-gray-500 capitalize tracking-wider">Type of Application</th>
                                <th className="px-6 py-3 text-left text-sm text-black font-bold text-gray-500 capitalize tracking-wider">Stage</th>
                                <th className="px-6 py-3 text-left text-sm text-black font-bold text-gray-500 capitalize tracking-wider">Status</th>
                                <th className="px-6 py-3 text-left text-sm text-black font-bold text-gray-500 capitalize tracking-wider w-28">Action</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white">
                            {localApplications?.map((application, index) => {
                                return (
                                    <tr key={application.id}
                                        onClick={() => navigate(`/checklists/${application.id}`)}
                                        className="border-b-[1px] border-grey-150/50 cursor-pointer hover:bg-grey-100 transition-all ease-in duration-150">
                                        {/* <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 tracking-wider flex items-center">
                                            <input type='checkbox' className="mr-2" />
                                            {index + 1}
                                        </td> */}
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 capitalize">{application.name}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 capitalize">{application.type_of_application}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 capitalize">
                                            <span className="bg-blue-400/15 rounded-xl font-semibold p-1 px-2 text-blue-400">
                                                {application.current_stage}
                                            </span>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 capitalize">
                                            <span className="bg-green-400/15 rounded-xl font-bold p-1 px-2 text-green-400">
                                                {application.current_status}
                                            </span>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 flex justify-start items-center gap-1.5" onClick={(e) => { e.stopPropagation() }}>
                                            <Link to={`/applications/${application.id}`} className="flex justify-center items-center p-2 rounded-xl bg-blue-600/15 cursor-pointer">
                                                <BiSolidPencil className='text-blue-600 text-lg' />
                                            </Link>
                                            <span onClick={() => setApplicationDetailSideBar(application.id)} className="flex justify-center items-center p-2 rounded-xl bg-green-400/15 cursor-pointer">
                                                <IoEyeOutline className='text-green-400 text-lg' />
                                            </span>
                                            <button disabled={loading} onClick={() => deleteApp(`${application.id}`)} className="flex justify-center items-center p-2 rounded-xl bg-red/20 cursor-pointer">
                                                <GoTrash className='text-red text-lg' />
                                            </button>
                                        </td>
                                    </tr>
                                )

                            })}

                        </tbody>
                    </table>
                </div>

                <div className="hidden sm:flex flex-col gap-4 my-4">
                    {localApplications?.map((application, index) => {
                        return (
                            <MobileViewApplication key={index} application={application} deleteApp={deleteApp} />
                        )
                    })}
                </div>
            </div>

            {filterPopup && <FilterPopup setFilterPopup={setFilterPopup} filter={filter} />}
        </div>
    )
}

export default Applications


interface FilterPopupProps {
    setFilterPopup: React.Dispatch<React.SetStateAction<boolean>>
    filter: () => void
}

const FilterPopup: React.FC<FilterPopupProps> = ({ setFilterPopup, filter }) => {
    const { applications } = useContext(Context)

    return (
        <div className="h-screen w-full fixed top-0 right-0 bg-black/50 z-[50] flex justify-center items-center">
            <div className="bg-white rounded-2xl p-5 w-1/3 sm:w-[90%]">
                <div className="flex justify-between items-center">
                    <h1 className="text-black font-bold text-xl sm:text-lg">Apply Filters</h1>
                    <button onClick={() => { setFilterPopup(false) }} className='cursor-pointer bg-grey-50/50 rounded-md border-[1px] border-grey-150/20 p-1.5'>
                        <RxCross2 className='text-black text-xl sm:text-lg' />
                    </button>
                </div>

                <div className="flex flex-col gap-2 mt-4 justify-start items-start">
                    <div className='flex flex-col gap-1 relative w-full'>
                        <label className='text-black text-sm font-bold  sm:text-xs'>Select Type</label>
                        <select required name='type_of_application' onChange={(e) => {
                            localStorage.setItem('type_of_application_filter', e.target.value)
                        }} className="border-[2px] border-grey-50/75 focus:border-black/50 transition-all ease-in duration-150 rounded-lg px-3 py-0 h-[56px] font-medium placeholder:text-black/50 text-black text-sm outline-none sm:text-xs sm:px-4 sm:h-9">
                            <option value={''}>Select Type</option>
                            <option selected={localStorage.getItem('type_of_application_filter') === 'visitor' ? true : false} value={'visitor'}>Visitor</option>
                            <option selected={localStorage.getItem('type_of_application_filter') === 'student' ? true : false} value={'student'}>Student</option>
                            <option selected={localStorage.getItem('type_of_application_filter') === 'work' ? true : false} value={'work'}>Work</option>
                            <option selected={localStorage.getItem('type_of_application_filter') === 'other' ? true : false} value={'other'}>Other</option>
                        </select>
                    </div>
                    <div className='flex flex-col gap-1 relative w-full'>
                        <label className='text-black text-sm font-bold sm:text-xs'>Select Stage</label>
                        <select name='type_of_application' onChange={(e) => {
                            localStorage.setItem('current_stage_filter', e.target.value)
                        }} className="border-[2px] border-grey-50/75 focus:border-black/50 transition-all ease-in duration-150 rounded-lg px-3 py-0 h-[56px] font-medium placeholder:text-black/50 text-black text-sm outline-none sm:text-xs sm:px-4 sm:h-9">
                            <option value={''}>Select Type</option>
                            <option selected={localStorage.getItem('current_stage_filter') === 'In Progress' ? true : false} value={'In Progress'}>In Progress</option>
                            <option selected={localStorage.getItem('current_stage_filter') === 'Submitted' ? true : false} value={'Submitted'}>Submitted</option>
                            <option selected={localStorage.getItem('current_stage_filter') === 'Rebuttal' ? true : false} value={'Rebuttal'}>Rebuttal</option>
                        </select>
                    </div>
                    <div className='flex flex-col gap-1 relative w-full'>
                        <label className='text-black text-sm font-bold sm:text-xs'>Select Status</label>
                        <select name='type_of_application' onChange={(e) => {
                            localStorage.setItem('current_status_filter', e.target.value)
                        }} className="border-[2px] border-grey-50/75 focus:border-black/50 transition-all ease-in duration-150 rounded-lg px-3 py-0 h-[56px] font-medium placeholder:text-black/50 text-black text-sm outline-none sm:text-xs sm:px-4 sm:h-9">
                            <option value={''}>Select Type</option>
                            <option selected={localStorage.getItem('current_status_filter') === 'Active' ? true : false} value={'Active'}>Active</option>
                            <option selected={localStorage.getItem('current_status_filter') === 'Inactive' ? true : false} value={'Inactive'}>Inactive</option>
                            <option selected={localStorage.getItem('current_status_filter') === 'Action Needed' ? true : false} value={'Action Needed'}>Action Needed</option>
                            <option selected={localStorage.getItem('current_status_filter') === 'Completed' ? true : false} value={'Completed'}>Completed</option>
                        </select>
                    </div>

                    <button onClick={()=>{filter(); setFilterPopup(false)}} className='disabled:opacity-75 self-end disabled:cursor-wait rounded-3xl border-[2px] border-blue-600 px-6 py-3 sm:px-3 sm:py-2 sm:text-xs text-white bg-blue-600 font-bold flex text-sm justify-center items-center gap-3'>
                        Apply
                    </button>
                </div>
            </div>
        </div>
    )
}

