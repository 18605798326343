import React, { useContext, useEffect, useState } from 'react'
import { BsCloudUploadFill, BsStars } from 'react-icons/bs'
import { GoPlus, GoTrash } from 'react-icons/go'
import { Link, useNavigate } from 'react-router-dom'
import { LuFileText } from "react-icons/lu";
import { GiCheckMark } from "react-icons/gi";
import { HiOutlineClipboardDocument, HiOutlineDocumentDuplicate } from 'react-icons/hi2';
import { CiSearch } from 'react-icons/ci';
import { Context } from '../state/Provider';
import { Application, Notification } from '../types';
import { BiSolidPencil } from 'react-icons/bi';
import { IoDocumentTextOutline, IoEyeOutline } from 'react-icons/io5';
import { deleteApplication } from '../services/deleteApplication';
import { toast } from 'react-toastify';
import MobileViewApplication from '../components/MobileViewApplication';
import { TiDocumentText } from "react-icons/ti";
import { readNotification } from '../services/readNotification';
import NotificationItem from '../components/NotificationItem';

function Dashboard() {

    const { applications, setApplicationDetailSideBar, setRefreshApplications, storage, applicants, notification } = useContext(Context)
    const [localNotification, setLocalNotification] = useState<Notification[]>([])
    const [localApplications, setLocalApplication] = useState<Application[] | null>()

    const [loading, setLoading] = useState<boolean>(false)
    const navigate = useNavigate()

    const searchApplications = (e: React.ChangeEvent<HTMLInputElement>) => {
        const search = e.target.value
        if (search === '') {
            let sortedApplications = applications?.data?.sort((a, b) => a.updated_date > b.updated_date ? -1 : 1)
            setLocalApplication(sortedApplications ?? null)
        } else {
            const filteredApplications = applications?.data?.filter(application => application.name.toLowerCase().includes(search.toLowerCase()))
            let sortedApplications = filteredApplications?.sort((a, b) => a.updated_date > b.updated_date ? -1 : 1)
            setLocalApplication(sortedApplications ?? null)
        }
    }
    const searchNotification = (e: React.ChangeEvent<HTMLInputElement>) => {
        const search = e.target.value
        if (search === '') {
            setLocalNotification(notification)
        } else {
            const filteredNotification = notification?.filter(notification => notification.message.toLowerCase().includes(search.toLowerCase()))
            setLocalNotification(filteredNotification ?? [])
        }
    }


    const deleteApp = async (id: string) => {
        const confirm = window.confirm('Are you sure you want to delete this application?')
        if (!confirm) return
        setLoading(true)
        try {
            const response = await deleteApplication(id)
            if (response.status === 204) {
                toast.success("Application deleted.")
                setRefreshApplications(prevState => !prevState)
            }
            else
                toast.error(response.data.detail)
        } catch (error) {
            console.error(error);
        }
        setLoading(false)
    }

    useEffect(()=>{
        setLocalNotification(notification)
    }, [notification])

    useEffect(() => {
        let sortedApplications = applications?.data?.sort((a, b) => a.updated_date > b.updated_date ? -1 : 1)
        setLocalApplication(sortedApplications ?? null)
    }, [applications])

    return (
        <div className='py-10 px-8 flex flex-col gap-8 sm:px-4'>
            <div className="flex justify-between items-center sm:flex-col sm:gap-3 sm:justify-start sm:items-start">
                <div>
                    <h1 className="text-black font-bold text-2xl flex gap-2 items-center capitalize">Welcome, {JSON.parse(localStorage.getItem('user') || "{}").first_name}
                        <img src={require('../assets/image 6.png')} />
                    </h1>
                    <p className="text-sm text-stalle-700 font-medium mt-1 sm:text-xs">Here's what's happening with your applications today</p>
                </div>
                <div className="flex gap-2 sm:justify-start">

                    <Link to='/applications/create' className="rounded-3xl bg-[#6C6DB3] px-3 py-3 text-white font-semibold flex justify-center items-center capitalize gap-1 sm:text-xs sm:py-2">
                        <GoPlus className='text-white text-xl' />
                        create new application
                    </Link>
                    <Link to='create' className="rounded-3xl bg-blue-600 px-3 py-3 text-white font-semibold flex justify-center items-center capitalize gap-1 sm:text-xs sm:py-2">
                        <BsStars className='text-white text-xl rotate-180' />
                        Start with AI
                    </Link>
                </div>
            </div>

            <div className='flex justify-between items-stretch gap-5 sm:flex-col'>

                <div className='grid grid-cols-2 sm:grid-cols-2 gap-4 w-[50%] sm:w-full sm:gap-2'>

                    <div className='flex flex-col p-4 rounded-xl border-[2px] border-grey-50/75 justify-start items-start sm:p-3'>
                        <span className='flex justify-center items-center p-3 bg-[#8B5CF6] rounded-full'>
                            <LuFileText className='text-white text-2xl' />
                        </span>
                        <p className='text-stalle-500 font-semibold text-sm mt-4 sm:text-xs'>Number of active applications </p>
                        <p className='text-black font-medium text-2xl sm:text-lg'>{applications?.data.filter(app => app.current_status === 'Active')?.length ?? 0} </p>
                    </div>

                    <div className='flex flex-col p-4 rounded-xl border-[2px] border-grey-50/75 justify-start items-start sm:p-3'>
                        <span className='flex justify-center items-center p-3 bg-[#10B981] rounded-full'>
                            <GiCheckMark className='text-white text-xl sm:text-lg' />
                        </span>
                        <p className='text-stalle-500 font-semibold text-sm mt-4  sm:text-xs'>Number of completed applications </p>
                        <p className='text-black font-medium text-2xl'>{applications?.data.filter(app => app.current_status === 'Completed')?.length ?? 0} </p>
                    </div>

                    <div className='flex flex-col p-4 rounded-xl border-[2px] border-grey-50/75 justify-start items-start sm:p-3'>
                        <span className='flex justify-center items-center p-3 bg-[#FB923C] rounded-full'>
                            <HiOutlineClipboardDocument className='text-white text-2xl' />
                        </span>
                        <p className='text-stalle-500 font-semibold text-sm mt-4  sm:text-xs'>Number of Applicants </p>
                        <p className='text-black font-medium text-2xl sm:text-lg'>{applicants?.count ?? 0} </p>
                    </div>

                    <div className='flex flex-col p-4 rounded-xl border-[1px] border-grey-50 justify-start items-start bg-grey-50/50 sm:p-3'>
                        <div className='flex justify-start items-center gap-2'>
                            <span className='flex justify-center items-center p-3 bg-[#0EA5E9] rounded-full'>
                                <BsCloudUploadFill className='text-white text-2xl' />
                            </span>
                            <p className='text-stalle-500 font-bold text-sm'>Storage </p>
                        </div>
                        <div className='mt-6 w-full'>
                            <span className='flex justify-between items-center'>
                                <p className='text-stalle-700 text-sm font-medium sm:text-xs'>{storage?.total_used_mb?.toFixed(1) ?? 0} Mb</p>
                                <p className='text-stalle-700 text-sm font-semibold sm:text-xs'>{storage?.storage_limit_mb ?? 0} Mb</p>
                            </span>
                            <div className='w-full bg-white h-2 rounded-3xl mt-1 overflow-hidden'>
                                <div className='bg-blue-600 h-full rounded-3xl' style={{ width: `${(storage?.total_used_mb / storage?.storage_limit_mb) * 100 ?? 0}%` }}></div>
                            </div>
                        </div>
                    </div>

                </div>


                <div className="border-[1px] border-grey-50 rounded-2xl w-[50%] sm:w-full">
                    <div className="flex justify-between items-end px-4 py-5 sm:flex-col sm:items-start sm:gap-2">
                        <div>
                            <h1 className="text-black font-bold text-lg flex gap-2 items-center capitalize sm:text-base">Activity Log</h1>
                            <p className="text-sm text-stalle-700 font-medium sm:text-xs">Last Updated: 23, May 2023</p>
                        </div>

                        <div className="flex gap-2 sm:w-full">
                            <div className='bg-grey-50/40 rounded-3xl w-[300px] border-[1px] border-grey-100 flex items-center gap-1 px-4 p-3 sm:w-full sm:p-2'>
                                <CiSearch className='text-black text-xl' />
                                <input onChange={searchNotification} type='text' placeholder='Search for anything' className='w-full font-medium placeholder:text-black/75 text-black/75 h-full text-sm bg-transparent outline-none' />
                            </div>
                        </div>
                    </div>

                    <div className='flex flex-col h-[230px] overflow-y-scroll'>
                        {localNotification?.map((notification, index) => (
                           <NotificationItem key={index} notification={notification} />
                        ))}
                    </div>
                </div>


            </div>


            <div className="border-[1px] border-grey-50 rounded-2xl sm:border-none">
           

                <div className="flex justify-between items-end px-4 py-5 sm:flex-col sm:items-start sm:gap-1.5 sm:p-0">
                    <div>
                        <h1 className="text-black font-bold text-lg flex gap-2 items-center capitalize sm:text-base">Last Updated Applications</h1>
                        <p className="text-sm text-stalle-700 font-medium sm:text-xs">You have currently <b>{applications.count}</b> applications</p>
                    </div>

                    <div className="flex gap-2 sm:w-full">
                        <div className='bg-grey-50/40 rounded-3xl w-[300px] border-[1px] border-grey-100 flex items-center gap-1 px-4 p-3 sm:px-3 sm:py-2 sm:w-full '>
                            <CiSearch className='text-black text-xl' />
                            <input onChange={searchApplications} type='text' placeholder='Search for anything' className='w-full font-medium placeholder:text-black/75 text-black/75 h-full text-sm bg-transparent outline-none sm:text-xs' />
                        </div>
                    </div>
                </div>

                <div className="overflow-x-auto sm:hidden">
                    <table className="min-w-full lg:table">
                        <thead className="bg-[#F1F5F9]">
                            <tr>
                                {/* <th className="px-6 py-3 text-left text-sm text-black font-medium text-gray-500 capitalize tracking-wider flex items-center">
                                    <input type='checkbox' className="mr-2" /> Sr.No
                                </th> */}
                                <th className="px-6 py-3 text-left text-sm text-black font-bold text-gray-500 capitalize tracking-wider">Name</th>
                                <th className="px-6 py-3 text-left text-sm text-black font-bold text-gray-500 capitalize tracking-wider">Type of Application</th>
                                <th className="px-6 py-3 text-left text-sm text-black font-bold text-gray-500 capitalize tracking-wider">Stage</th>
                                <th className="px-6 py-3 text-left text-sm text-black font-bold text-gray-500 capitalize tracking-wider">Status</th>
                                <th className="px-6 py-3 text-left text-sm text-black font-bold text-gray-500 capitalize tracking-wider w-28">Action</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white">
                            {localApplications?.map((application, index) => {
                                return (
                                    <tr key={application.id}
                                        onClick={() => navigate(`/checklists/${application.id}`)}
                                        className="border-b-[1px] border-grey-150/50 cursor-pointer hover:bg-grey-100 transition-all ease-in duration-150">
                                        {/* <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 tracking-wider flex items-center">
                                            <input type='checkbox' className="mr-2" />
                                            {index + 1}
                                        </td> */}
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 capitalize">{application.name}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 capitalize">{application.type_of_application}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 capitalize">
                                            <span className="bg-blue-400/15 rounded-xl font-semibold p-1 px-2 text-blue-400">
                                                {application.current_stage}
                                            </span>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 capitalize">
                                            <span className="bg-green-400/15 rounded-xl font-bold p-1 px-2 text-green-400">
                                                {application.current_status}
                                            </span>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 flex justify-start items-center gap-1.5" onClick={(e) => { e.stopPropagation() }}>
                                            <Link to={`/applications/${application.id}`} className="flex justify-center items-center p-2 rounded-xl bg-blue-600/15 cursor-pointer">
                                                <BiSolidPencil className='text-blue-600 text-lg' />
                                            </Link>
                                            <span onClick={() => setApplicationDetailSideBar(application.id)} className="flex justify-center items-center p-2 rounded-xl bg-green-400/15 cursor-pointer">
                                                <IoEyeOutline className='text-green-400 text-lg' />
                                            </span>
                                            <button disabled={loading} onClick={() => deleteApp(`${application.id}`)} className="flex justify-center items-center p-2 rounded-xl bg-red/20 cursor-pointer">
                                                <GoTrash className='text-red text-lg' />
                                            </button>
                                        </td>
                                    </tr>
                                )

                            })}

                        </tbody>
                    </table>

                </div>

                <div className="hidden sm:flex flex-col gap-4 my-4">
                    {localApplications?.map((application, index) => {
                        return (
                          <MobileViewApplication key={index} application={application} deleteApp={deleteApp}/>
                        )
                    })}
                </div>
            </div>

        </div>
    )
}

export default Dashboard
