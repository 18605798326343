import axios from 'axios';
// import { Application } from '../types'; // Adjust path to where the types are defined
import { BASE_SERVER } from '../constants';
import { getToken } from '../utils/getToken';
import { Applicant, Checklist } from '../types';

interface Application {
    name: string;
    description: string;
    current_stage: string;
    current_status: string;
    country_of_application: string;
    type_of_application: string;
    applicants: Applicant[],
    checklists: Checklist[]
}


interface Application {
    id: number;
}

export const getSingleApplication = async (id:string): Promise<Application> => {
    try {
        const response = await axios.get<Application>(`${BASE_SERVER}/applications/${id}`, {
            headers: {
                'Authorization': `Bearer ${getToken()}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        // Handle errors or re-throw for the caller to handle
        throw error;
    }
};
