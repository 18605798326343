import React, { useEffect, useState } from 'react'
import { HiMiniBars3 } from "react-icons/hi2";
import { RxCross2 } from 'react-icons/rx';
import { Link } from 'react-router-dom';

const OutterNav: React.FC = () => {
  const [scrolled, setScrolled] = useState<boolean>(false);
  const [drawer, setDrawer] = useState(false);

  useEffect(() => {
    function handleScroll() {
      const scrolled: number = window.scrollY;
      if (scrolled > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <div className={`fixed top-0 left-0 w-full ${!scrolled ? 'bg-transparent' : 'bg-white shadow-sm'} transition-all ease-in duration-100 z-50`}>
      <div className='flex justify-between items-center px-16 py-1 pb-2 sm:px-5 sm:py-3'>
        <div className='flex justify-start items-center gap-8'>
          <img src={require('../assets/logo.png')} className='w-[200px] cursor-pointer sm:w-[150px] z-[55]' />
          <nav className='flex justify-center items-center gap-2 sm:hidden'>
            <a href='#home' className='text-black font-normal text-base hover:text-blue-600 transition-all ease-in duration-150 px-3 py-1 border-transparent border-[1px] rounded-3xl hover:border-blue-600'>Home</a>
            <a href='#pricing' className='text-black font-normal text-base hover:text-blue-600 transition-all ease-in duration-150 px-3 py-1 border-transparent border-[1px] rounded-3xl hover:border-blue-600'>Pricing</a>
            <Link to='/booking' className='text-black font-normal text-base hover:text-blue-600 transition-all ease-in duration-150 px-3 py-1 border-transparent border-[1px] rounded-3xl hover:border-blue-600'>Consulting</Link>
            <a href='#about' className='text-black font-normal text-base hover:text-blue-600 transition-all ease-in duration-150 px-3 py-1 border-transparent border-[1px] rounded-3xl hover:border-blue-600'>About Us</a>
          </nav>
        </div>

        <div className='flex justify-start items-stretch gap-3 mt-2 sm:hidden'>
          <Link to='/sign-up' className='rounded-lg border-[1px] bg-white border-blue-600 px-6 py-2 text-blue-600 font-semibold flex justify-center items-center gap-3'>
            Signup
          </Link>
          <Link to='/login' className='rounded-lg bg-blue-600 px-6 py-2 text-white font-semibold'>
            Login
          </Link>
        </div>

        <button onClick={() => { setDrawer(!drawer) }} className='hidden sm:flex cursor-pointer bg-white rounded-md border-[1px] border-grey-150/20 p-1.5 z-[55]'>
          {!drawer ? <HiMiniBars3 className='text-black text-xl' />
            : <RxCross2 className='text-black text-xl' />}
        </button>
      </div>

      <div className={`hidden w-full sm:h-screen bg-white absolute sm:flex justify-center items-center gap-5 sm:flex-col ${drawer? 'top-0 opacity-100': 'top-[-2000px] opacity-20'} transition-all ease-in duration-100`}>
        <a href='#' onClick={(()=>setDrawer(false))} className='text-black font-normal text-base hover:text-blue-600 transition-all ease-in duration-150'>Home</a>
        <a href='#' onClick={(()=>setDrawer(false))} className='text-black font-normal text-base hover:text-blue-600 transition-all ease-in duration-150'>Pricing</a>
        <a href='#' onClick={(()=>setDrawer(false))} className='text-black font-normal text-base hover:text-blue-600 transition-all ease-in duration-150'>Consulting</a>
        <a href='#' onClick={(()=>setDrawer(false))} className='text-black font-normal text-base hover:text-blue-600 transition-all ease-in duration-150'>About Us</a>
        <div  onClick={(()=>setDrawer(false))} className='flex justify-start items-stretch gap-3 mt-2'>
          <Link to='/sign-up' className='rounded-lg border-[1px] bg-white border-blue-600 px-6 py-2 text-blue-600 font-semibold flex justify-center items-center gap-3'>
            Signup
          </Link>
          <Link to='/login' className='rounded-lg bg-blue-600 px-6 py-2 text-white font-semibold'>
            Login
          </Link>
        </div>
      </div>

    </div>
  )
}

export default OutterNav
