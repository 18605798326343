import React, { useState, useCallback } from 'react';
import axios, { AxiosResponse } from 'axios';
import { useDropzone } from 'react-dropzone';
import { getToken } from '../utils/getToken';
import { BASE_SERVER } from '../constants';

// Define the success response structure
interface SuccessResponse {
    data: {
        file_name: string;
        file_type: string;
        file_path: string;
        id: number;
        owner_id: number;
        doctype_id: number;
        application_id: number;
        checklist_id: number;
        created_date: string;
        updated_date: string;
    }[];
    count: number;
}

// Define the failure response structure
interface FailureResponse {
    status: number;
    detail?: string;
}

// Function to upload documents
export const uploadDocs = async (
    doctype_id: string,
    formData: FormData
): Promise<AxiosResponse<SuccessResponse | FailureResponse>> => {
    try {
        const response: AxiosResponse<SuccessResponse> = await axios.post(
            `${BASE_SERVER}/upload_documents/${doctype_id}`,
            formData,
            {
                headers: {
                    'Authorization': `Bearer ${getToken()}`,
                    'Content-Type': 'multipart/form-data', // Corrected to handle file uploads
                    'Accept': 'application/json',
                }
            }
        );
        return response;
    } catch (error: any) {
        if (error.response) {
            return error.response;
        } else if (error.request) {
            return {
                data: { detail: 'No response from server' },
                status: 0,
                statusText: '',
                headers: {},
                config: {},
                request: error.request
            } as AxiosResponse<FailureResponse>;
        } else {
            return {
                data: { detail: error.message },
                status: 0,
                statusText: '',
                headers: {},
                config: {},
                request: null
            } as AxiosResponse<FailureResponse>;
        }
    }
};

