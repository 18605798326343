import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getSharedChecklist } from '../services/getSharedChecklist'
import { Checklist } from '../types'
import { getSharedApplication } from '../services/getSharedApplication'
import { PiDownloadSimple, PiUploadSimple } from 'react-icons/pi'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'
import { downloadDocTypeFilesShared } from '../services/downloadDocTypeFilesShared'
import { IoArrowBack } from 'react-icons/io5'



function SharedCheclist({ setuploadSharedDocument, uploadSharedDocument }: { setuploadSharedDocument: React.Dispatch<React.SetStateAction<boolean>>; uploadSharedDocument: boolean }) {
    const { token } = useParams()
    const [selectedChecklist, setSelectedCheclist] = useState<Checklist>({} as Checklist)
    const [checklists, setChecklists] = useState<Checklist[]>([])
    const [apiCalled, setApiCalled] = useState<boolean>(false)
    const [downloading, setDownloading] = useState<boolean>(false)

    const getData = async () => {
        if (token)
            try {
                let response: any
                if (window.location.pathname.includes('checklist')) {
                    response = await getSharedChecklist(token)
                    if (window.innerWidth > 768)
                        setSelectedCheclist(response)
                    setChecklists([response])
                }
                else {
                    response = await getSharedApplication(token)
                    if (window.innerWidth > 768)
                        setSelectedCheclist(response?.checklists[0])
                    setChecklists(response.checklists)
                }
                setApiCalled(true)
            } catch (error) {
                console.log(error);
            }
    }

    useEffect(() => {
        getData()
    }, [token, uploadSharedDocument])
    return (
        <div className={`flex h-full flex-1 justify-between w-screen overflow-hidden mx-0 gap-0`}>
            <div className={`flex flex-col justify-start items-start h-screen gap-6 w-[30%] py-5 px-8 sm:px-4 sm:w-full sm:h-auto`}>
                <span className='flex justify-start items-center gap-2'>
                    {selectedChecklist.id && (
                        <button
                            onClick={() => {
                                setSelectedCheclist({} as any)
                            }}
                            className='hidden outline-none sm:flex cursor-pointer bg-grey-50/40 rounded-full border-[1px] border-grey-150/20 p-1.5 z-[55]'
                        >
                            <IoArrowBack className='text-black text-md' />
                        </button>
                    )}
                    <img src={require('../assets/logo.png')} className='w-[170px] cursor-pointer sm:w-[150px] sm:left-4 sm:top-3' />
                </span>
                {!apiCalled && <div className='bg-white w-full h-[450px] rounded-lg flex justify-center items-center'>
                    <img src='https://cdn.pixabay.com/animation/2023/10/08/03/19/03-19-26-213_512.gif'
                        className='w-16'
                    />
                </div>}
                {apiCalled && <div className={`flex justify-start flex-col gap-5 w-full h-[95vh] overflow-y-scroll noscrollbar `}>
                    {checklists.map((checklist, index) => {
                        const totalDocTypes: number = Number(checklist.doctypes.length) ?? 0;
                        const filledDocTypes: number = Number(checklist.doctypes.filter(docType => docType.documents.length > 0).length) ?? 0
                        const progress: number = totalDocTypes > 0 ? Number((filledDocTypes / totalDocTypes) * 100) : 0;
                        return <div key={index} onClick={() => setSelectedCheclist(checklist)} className={`cursor-pointer flex flex-col border-[1px] border-grey-50 rounded-xl w-full px-3 py-4 gap-4 ${selectedChecklist.id === checklist.id && 'shadow-md !border-blue-600'}`}>
                            <div className='flex justify-between items-center w-full'>
                                <span>
                                    <p className='text-stalle-700 text-xs font-medium'>Applicant Name</p>
                                    <p className='text-black text-base font-bold'>{checklist.name}</p>
                                </span>
                                <span className='flex justify-end items-center gap-2'>
                                    <p className='bg-green-400/20 font-semibold text-green-400 rounded-xl whitespace-nowrap capitalize text-[10px] px-2 py-1'>{checklist.current_status}</p>

                                </span>
                            </div>

                            <div className='flex justify-between items-center'>
                                <span>
                                    <p className='text-stalle-700 text-xs font-medium'>Last Updated</p>
                                    <p className='text-black text-sm font-semibold'>{checklist.updated_date.replace('T', ' ').substr(0, 10)}</p>
                                </span>
                            </div>
                            <div className='flex justify-between items-center'>
                                <span>
                                    <p className='text-stalle-700 text-xs font-medium'>Shared</p>
                                    <p className='text-black text-sm font-semibold'>Yes</p>
                                </span>
                                {checklist.due_date && <span className='w-1/2'>
                                    <p className='text-stalle-700 text-xs font-medium'>Due Date </p>
                                    <p className='text-black text-sm font-semibold'>{checklist.due_date?.replace('T', ' ').substr(0, 10)}</p>
                                </span>}
                            </div>
                            <div className='bg-grey-50 rounded-xl p-2'>
                                <span className='flex justify-between items-center'>
                                    <p className='text-stalle-700 text-sm font-medium'>Progress</p>
                                    <p className='text-stalle-700 text-sm font-semibold'>{progress.toFixed(0)}%</p>
                                </span>
                                <div className='w-full bg-white h-2 rounded-3xl mt-1'>
                                    <div className='bg-green-400 h-full rounded-3xl' style={{ width: `${progress}%` }}></div>
                                </div>
                            </div>
                        </div>
                    })}

                </div>}
            </div>


            <div className={`bg-grey-100 w-[70%] h-[calc(100vh)] overflow-scroll py-8 px-6 pl-10 flex flex-col gap-3 overflow-y-scroll overflow-x-hidden sm:absolute sm:w-full sm:bg-white sm:px-4 sm:top-16 sm:pt-0 ${!selectedChecklist?.id && 'sm:hidden'} `}>

                <div className='flex justify-between items-center'>
                    <h2 className='text-base text-black font-bold'>Documents</h2>
                </div>
                {!apiCalled && <div className=' w-full h-[500px] rounded-lg flex justify-center items-center'>
                    <img src='https://cdn.pixabay.com/animation/2023/10/08/03/19/03-19-26-213_512.gif'
                        className='w-16'
                    />
                </div>}
                {apiCalled && selectedChecklist?.doctypes?.length === 0 && <div className='bg-white w-full h-96 sm:h-40 rounded-lg border-[1px] border-grey-50 flex justify-center items-center'>
                    <p className='text-sm text-black font-medium'>No doc type created</p>
                </div>}
                {apiCalled && selectedChecklist?.doctypes?.map((doctype, index) => (
                    <div key={index} className='bg-white w-full rounded-lg border-[1px] border-grey-50'>
                        <div className='py-3 px-6 flex justify-between items-center sm:px-3 sm:py-2 '>
                            <h1 className='text-black font-bold text-base'>{doctype.name}</h1>
                            <div className='flex justify-end gap-1 items-start'>
                                <button onClick={() => {
                                    localStorage.setItem('doctype_id', JSON.stringify(doctype.id))
                                    localStorage.setItem('allowed_size', JSON.stringify(doctype.allowed_size))
                                    localStorage.setItem('shared_token', token ?? '')
                                    setuploadSharedDocument(true)
                                }} className='border-[1px] border-stalle-700/50 rounded-3xl px-3 py-1.5 sm:p-2 text-black cursor-pointer font-semibold text-sm'>
                                    <span className='sm:hidden'>Upload Files</span>
                                    <PiUploadSimple className='text-black text-sm hidden sm:flex' />
                                </button>
                                <button onClick={async () => {
                                    setDownloading(true)
                                    await downloadDocTypeFilesShared(doctype.id, token ?? '');
                                    setDownloading(false)
                                }} className='border-[1px] border-stalle-700/50 rounded-full p-2 text-blue-400 cursor-pointer font-bold text-sm'>
                                    {!downloading ?
                                        <PiDownloadSimple className='text-black text-sm' />
                                        :
                                        <AiOutlineLoading3Quarters className='text-black text-sm loading' />}
                                </button>
                            </div>
                        </div>
                        <hr className='w-full h-[1px] border-t-[1px] border-stalle-700/30' />
                        <div className='py-4 px-6 flex flex-col gap-0 sm:px-3 sm:py-2 '>
                            <h2 className='text-stalle-500 font-semibold text-sm'>Description</h2>
                            <p className='text-black font-normal text-base  sm:text-sm'>{doctype.description}</p>
                            {doctype.documents.length > 0 && <>
                                <h2 className='text-stalle-500 font-semibold text-sm mt-2'>Uploaded Documents</h2>
                                <div id='slider' className='slider w-full h-full overflow-x-scroll scroll whitespace-nowrap scroll-smooth scrollbar-hide mt-2'>
                                    {doctype.documents?.map((document, index) => (
                                        <div key={index} className='bg-grey-50/60 p-2 inline-block whitespace-normal rounded-xl mr-3 cursor-pointer' >
                                            <div className='doc flex justify-start items-center gap-2 relative'>
                                                {document.file_type.includes('application') && <img src={require('../assets/Group 11712792291.png')} className='w-122  sm:w-10' />}
                                                {document.file_type.includes('image') && <img src={require('../assets/Group 1171279229.png')} className='w-122 sm:w-10' />}
                                                <div className='w-44'>
                                                    <p className='text-black text-sm font-semibold'>{document.file_name.length > 17 ? `${document.file_name.substr(0, 17)}...` : document.file_name}</p>
                                                    <p className='text-stalle-500 text-xs font-normal'>{document.file_type}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>}
                        </div>
                    </div>
                ))}


            </div>
        </div>
    )
}

export default SharedCheclist
