
import axios, { AxiosResponse } from 'axios';
import { getToken } from '../utils/getToken';
import { BASE_SERVER } from '../constants';

interface Checklist {
    name: string
    due_date: string;
    share_link: string;
    current_status: string;
    application_id: number;
    applicant_id: number
}

// Define the failure response structure
interface FailureResponse {
    status: number;
    detail?: string;
    // Add other fields if the API provides more details
}

interface Applicant {
    first_name: string;
    last_name: string;
    email: string;
    full_name: string;
}


// Function to create an application
export const createChecklist = async (
    checklist : Checklist,
    applicant: Applicant
): Promise<AxiosResponse<FailureResponse>> => {


    try {
        const response: AxiosResponse<FailureResponse> = await axios.post(`${BASE_SERVER}/checklists/create_with_applicant`, {
            checklist_in: checklist,
            applicant_in: applicant
        }, {
            headers: {
                'Authorization': `Bearer ${getToken()}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        return response;
    } catch (error: any) {
        if (error.response) {
            return error.response;
        } else if (error.request) {
            return {
                data: { detail: 'No response from server' },
                status: 0,
                statusText: '',
                headers: {},
                config: {},
                request: error.request
            } as AxiosResponse<FailureResponse>;
        } else {
            return {
                data: { detail: error.message },
                status: 0,
                statusText: '',
                headers: {},
                config: {},
                request: null
            } as AxiosResponse<FailureResponse>;
        }
    }
};
