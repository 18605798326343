import React, { useContext, useEffect, useRef } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { getSingleApplication } from '../services/getSingleApplication'
import { Applicant, Checklist } from '../types';
import { IoArrowBack } from 'react-icons/io5';
import { getChecklist } from '../services/getChecklist';
import { GoLink, GoPlus, GoTrash } from 'react-icons/go';
import { PiDownloadSimple, PiShareFatThin, PiUploadSimple } from "react-icons/pi";
import { Context } from '../state/Provider';
import { FiFlag } from 'react-icons/fi';
import { deleteDocType } from '../services/deleteDocType';
import { toast } from 'react-toastify';
import { deleteChecklist } from '../services/deleteChecklist';
import { deleteDoc } from '../services/deleteDoc';
import { generateShareLinkChecklist } from '../services/generateShareLinkChecklist';
import { FaRegTrashAlt } from "react-icons/fa";
import { BASE_SERVER } from '../constants';
import { getToken } from '../utils/getToken';
import { downloadChecklistFiles } from '../services/downloadChecklistFiles';
import { downloadDocktype } from '../services/downloadDocktype';
import { HiOutlineDotsVertical } from 'react-icons/hi';
import { AiOutlineLoading3Quarters } from "react-icons/ai";

interface Application {
    name: string;
    description: string;
    country_of_application: string;
    type_of_application: string;
    checklists: Checklist[]
}


function ChecklistsApp() {
    const { setCreateDocTypePopup, createDocTypePopup, uploadFilesDocTypePopup, setuploadFilesCreateDocTypePopup, setDocViewerSideBar, sidebar, selectedchecklist, setSelectedChecklist } = useContext(Context)
    const [checklists, setChecklists] = React.useState<Checklist[]>([])
    const [loading, setLoading] = React.useState<boolean>(false)
    const [apiCalled, setAppiCalled] = React.useState<boolean>(false)
    const [mobMenu, setMobMenu] = React.useState<boolean>(false)
    const [downloading, setDownloading] = React.useState<boolean>(false)
    const { id } = useParams()
    const navigate = useNavigate()
    const mobMenuRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (mobMenuRef.current && !mobMenuRef.current.contains(event.target as Node)) {
                setMobMenu(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [mobMenuRef]);

    const getAppCheclists = async () => {
        if (id)
            try {
                const response: Application = await getSingleApplication(id)
                const checlistIds = response.checklists.map((checklist) => checklist.id)
                let _checklists: Checklist[] = []
                for (let i = 0; i < checlistIds.length; i++) {
                    const data: Checklist = await getChecklist(checlistIds[i])
                    _checklists.push(data)
                    if (i === 0 && !selectedchecklist.id && window.innerWidth > 768)
                        setSelectedChecklist(data)
                    else if (selectedchecklist.id === data.id) {
                        setSelectedChecklist(data)
                    }
                }
                setChecklists(_checklists)
                setAppiCalled(true)
            } catch (error) {
                console.log(error);
            }

    }

    const _deleteDocType = async (id: number) => {
        const confirm = window.confirm('Are you sure you want to delete this doc type?')
        if (!confirm) return
        setLoading(true)
        setMobMenu(false)
        try {
            const response = await deleteDocType(id)
            if (response.status === 204) {
                toast.success("DocType deleted.")
                getAppCheclists()
            }
            else
                toast.error(response.data.detail)
        } catch (error) {
            console.log(error);
        }
        setLoading(false)

    }

    const _deleteChecklist = async (id: number) => {
        const confirm = window.confirm('Are you sure you want to delete this checklist?')
        if (!confirm) return
        setLoading(true)
        try {
            const response = await deleteChecklist(id)
            if (response.status === 204) {
                toast.success("Checklist deleted.")
                getAppCheclists()
            }
            else
                toast.error(response.data.detail)
        } catch (error) {
            console.log(error);
        }
        setLoading(false)

    }

    const _deleteDoc = async (e: any, id: number) => {
        e.stopPropagation()
        const confirm = window.confirm('Are you sure you want to delete this document?')
        if (!confirm) return
        setLoading(true)
        try {
            const response = await deleteDoc(id)
            if (response.status === 204) {
                toast.success("Document deleted.")
                getAppCheclists()
            }
            else
                toast.error(response.data.detail)
        } catch (error) {
            console.log(error);
        }
        setLoading(false)
    }

    useEffect(() => {
        getAppCheclists()
        return () => {
            setSelectedChecklist({} as Checklist)
        }
    }, [id, createDocTypePopup, uploadFilesDocTypePopup])
    return (
        <div className={`flex h-full flex-1 justify-between ${sidebar ? 'w-[calc(100vw-240px)]' : 'w-screen'} overflow-hidden mx-0 gap-0 sm:w-full`}>
            <div className='flex flex-col justify-start items-start h-[calc(100vh)] gap-5 w-[30%] overflow-y-scroll  py-10 pl-8 pr-4 sm:w-full sm:px-4'>
                <button onClick={() => navigate(-1)} className='flex items-center gap-1 bg-grey-50 rounded-3xl px-2.5 py-2 outline-none text-black cursor-pointer text-sm sm:text-xs'>
                    <IoArrowBack className='text-black text-md' />
                    Back to applications
                </button>
                {checklists.length === 0 && !apiCalled && <div className='bg-white w-full h-[450px] rounded-lg flex justify-center items-center'>
                    <img src='https://cdn.pixabay.com/animation/2023/10/08/03/19/03-19-26-213_512.gif'
                        className='w-16'
                    />
                </div>}
                <div className='flex flex-col gap-4 w-full'>
                    {checklists.map((checklist) => {
                        const totalDocTypes: number = Number(checklist.doctypes.length) ?? 0;
                        const filledDocTypes: number = Number(checklist.doctypes.filter(docType => docType.documents.length > 0).length) ?? 0
                        const progress: number = totalDocTypes > 0 ? Number((filledDocTypes / totalDocTypes) * 100) : 0;

                        return <div onClick={() => setSelectedChecklist(checklist)} className={`cursor-pointer flex flex-col border-[1px] ${selectedchecklist.id == checklist.id ? 'border-blue-600 shadow-md' : 'border-grey-50'} rounded-xl w-full px-3 py-4 gap-4`}>
                            <div className='flex justify-between items-center w-full'>
                                <span>
                                    <p className='text-stalle-700 text-xs font-medium'>Applicant Name</p>
                                    <p className='text-black text-base font-bold'>{checklist.name}</p>
                                </span>
                                <span className='flex justify-end items-center gap-2'>
                                    <p className='bg-green-400/20 font-semibold text-green-400 rounded-xl whitespace-nowrap capitalize text-[10px] px-2 py-1'>{checklist.current_status}</p>
                                    <PiShareFatThin onClick={async (e) => {
                                        e.stopPropagation()
                                        e.preventDefault()
                                        const response = await generateShareLinkChecklist(checklist.id)
                                        if (response.status === 200 && 'share_link' in response.data) {
                                            const shareLink = response.data.share_link;
                                            await navigator.clipboard.writeText(shareLink);
                                            toast.success('Link copied');
                                        } else if ('detail' in response.data) {
                                            const errorDetail = response.data.detail;
                                            toast.error(errorDetail || 'Failed to generate share link');
                                        }
                                    }} className='text-black text-xl cursor-pointer' />
                                </span>
                            </div>

                            <div className='flex justify-between items-center'>
                                <span>
                                    <p className='text-stalle-700 text-xs font-medium'>Last Updated</p>
                                    <p className='text-black text-sm font-semibold'>{checklist.updated_date.replace('T', ' ').substr(0, 10)}</p>
                                </span>
                            </div>
                            <div className='flex justify-between items-start'>
                                <span>
                                    <p className='text-stalle-700 text-xs font-medium'>Shared</p>
                                    <p className='text-black text-sm font-semibold'>Yes</p>
                                </span>
                                {checklist.due_date && <span className='w-1/2'>
                                    <p className='text-stalle-700 text-xs font-medium'>Due Date </p>
                                    <p className='text-black text-sm font-semibold'>{checklist.due_date?.replace('T', ' ').substr(0, 10)}</p>
                                </span>}
                            </div>
                            <div className='bg-grey-50 rounded-xl p-2'>
                                <span className='flex justify-between items-center'>
                                    <p className='text-stalle-700 text-sm font-medium'>Progress</p>
                                    <p className='text-stalle-700 text-sm font-semibold'>{progress.toFixed(0)}%</p>
                                </span>
                                <div className='w-full bg-white h-2 rounded-3xl mt-1'>
                                    <div className='bg-green-400 h-full rounded-3xl' style={{ width: `${progress}%` }}></div>
                                </div>
                            </div>
                            <div className='flex gap-3'>
                                <button
                                    onClick={async() => {
                                        setDownloading(true)
                                        await downloadChecklistFiles(checklist.id)
                                        setDownloading(false)
                                    }}
                                    className='rounded-3xl bg-grey-100 px-6 py-2 flex-1 text-[#6C6DB3] font-semibold flex justify-center items-center text-sm border-[1px] border-stalle-700/50  gap-1'>
                                    Download Files
                                    {!downloading ?
                                        <PiDownloadSimple className='text-[#6C6DB3] text-lg' />
                                        :
                                        <AiOutlineLoading3Quarters className='text-[#6C6DB3] text-lg loading' />}
                                </button>
                                <button disabled={loading} onClick={() => _deleteChecklist(checklist.id)} className="flex justify-center items-center p-2 rounded-full bg-red/10 cursor-pointer">
                                    <GoTrash className='text-red text-lg' />
                                </button>
                            </div>
                        </div>
                    })}
                </div>

                <Link to='create' className="rounded-3xl bg-blue-600 px-3 py-3 text-white font-semibold flex justify-center items-center capitalize gap-1 w-full">
                    <GoPlus className='text-white text-xl' />
                    create checklist
                </Link>
            </div>


            <div className={`bg-grey-100 w-[70%] h-[calc(100vh)] overflow-scroll py-8 px-6 pl-9 flex flex-col gap-3 overflow-y-scroll overflow-x-hidden ${selectedchecklist.id ? 'sm:absolute sm:w-full sm:px-4 sm:py-10 sm:bg-white' : 'sm:hidden'}`}>

                <div className='flex justify-between items-center'>
                    <h2 className='text-base text-black font-bold'>Documents</h2>
                    <button onClick={() => setCreateDocTypePopup(selectedchecklist.id)} className='rounded-2xl bg-blue-600 px-3 py-2 text-white font-semibold flex justify-center items-center gap-1 text-sm sm:text-xs'>
                        <GoPlus className='text-white text-lg' />
                        Create DocType
                    </button>
                </div>
                {!apiCalled && <div className=' w-full h-[500px] rounded-lg flex justify-center items-center'>
                    <img src='https://cdn.pixabay.com/animation/2023/10/08/03/19/03-19-26-213_512.gif'
                        className='w-16'
                    />
                </div>}
                {apiCalled && selectedchecklist?.doctypes?.length === 0 && <div className='bg-white w-full h-96 sm:h-40 rounded-lg  flex justify-center items-center'>
                    <p className='text-sm text-black font-medium xm:text-xs'>No doc type created</p>
                </div>}
                {apiCalled && selectedchecklist?.doctypes?.map((doctype, index) => (
                   <div className='bg-white w-full rounded-lg sm:border-stalle-700/20'>
                   <div className='py-3 px-6 flex justify-between items-center sm:px-3 sm:py-2 relative'>
                       <h1 className='text-black font-bold text-base'>{doctype.name}</h1>
                       {/* <span onClick={(e) => { e.stopPropagation(); setMobMenu(!mobMenu) }} className="hidden sm:flex font-bold p-1 border-[1px] border-grey-50  rounded-full">
                           <HiOutlineDotsVertical className='text-black text-base' />
                       </span> */}

                       {/* {mobMenu && <div ref={mobMenuRef} className="absolute top-9 right-3 bg-white border-stalle-500/30 shadow-md border-[1px] p-2 rounded-lg w-36 flex flex-col gap-1.5 z-30">
                           <div onClick={()=>{
                                 localStorage.setItem('doctype_id', JSON.stringify(doctype.id))
                                 localStorage.setItem('application_id', JSON.stringify(Number(id)))
                                 localStorage.setItem('checklist_id', JSON.stringify(selectedchecklist.id))
                                 setuploadFilesCreateDocTypePopup(true)
                           }} className="flex justify-start items-center gap-2">
                               <span className="flex justify-center items-center p-1.5 rounded-full cursor-pointer border-stalle-700/50 border-[1px]">
                                   <PiUploadSimple className='text-black text-sm' />
                               </span>
                               <p className="text-xs text-black font-normal">Upload</p>
                           </div>
                           <div onClick={async()=>{
                               setMobMenu(false)
                               setDownloading(true)
                               await downloadDocktype(doctype.id); 
                               setDownloading(false)
                               }} className="flex justify-start items-center gap-2">
                               <span className="flex justify-center items-center p-1.5 rounded-full cursor-pointer border-stalle-700/50 border-[1px]">
                                   {!downloading ?
                                   <PiDownloadSimple className='text-black text-sm' />
                                   :
                                   <AiOutlineLoading3Quarters className='text-black text-sm loading' />}
                               </span>
                               <p className="text-xs text-black font-normal">Download</p>
                           </div>
                           <div onClick={() => _deleteDocType(doctype.id)} className="flex justify-start items-center gap-2">
                               <span className="flex justify-center items-center p-1.5 rounded-xl bg-red/20 cursor-pointer">
                                   <GoTrash className='text-red text-sm' />
                               </span>
                               <p className="text-xs text-black font-normal">Delete</p>
                           </div>
                       </div>} */}

                       <div className='flex justify-end gap-1'>
                           <button onClick={() => {
                               localStorage.setItem('doctype_id', JSON.stringify(doctype.id))
                               localStorage.setItem('allowed_size', JSON.stringify(doctype.allowed_size))
                               localStorage.setItem('application_id', JSON.stringify(Number(id)))
                               localStorage.setItem('checklist_id', JSON.stringify(selectedchecklist.id))
                               setuploadFilesCreateDocTypePopup(true)
                           }} className='border-[1px] border-stalle-700/50 rounded-3xl px-3 py-2 sm:p-2 text-black cursor-pointer font-semibold text-sm'>
                               <span className='sm:hidden'>Upload Files</span>
                               <PiUploadSimple className='text-black text-sm hidden sm:flex' />
                           </button>
                           <button onClick={async() => {
                               setDownloading(true)
                               await downloadDocktype(doctype.id); 
                               setDownloading(false)
                           }} className='border-[1px] border-stalle-700/50 rounded-full p-2 text-blue-400 cursor-pointer font-bold text-sm'>
                               {!downloading ?
                                   <PiDownloadSimple className='text-black text-sm' />
                                   :
                                   <AiOutlineLoading3Quarters className='text-black text-sm loading' />}
                           </button>
                           <button onClick={() => _deleteDocType(doctype.id)} className='border-[1px] border-red/50 bg-red/10 rounded-full p-2 text-blue-400 cursor-pointer font-bold text-sm'>
                               <GoTrash className='text-red text-lg sm:text-sm' />
                           </button>
                       </div>
                   </div>
                   <hr className='w-full h-[1px] border-t-[1px] border-stalle-700/30 sm:bg-stalle-700/20' />
                   <div className='py-4 px-6 flex flex-col gap-0 sm:px-3 sm:py-2'>
                       <h2 className='text-stalle-500 font-semibold text-sm'>Description</h2>
                       <p className='text-black font-normal text-base sm:text-sm'>{doctype.description}</p>
                       {doctype.documents.length > 0 && <>
                           <h2 className='text-stalle-500 font-semibold text-sm mt-2'>Uploaded Documents</h2>
                           <div id='slider' className='slider w-full h-full overflow-x-scroll scroll whitespace-nowrap scroll-smooth scrollbar-hide mt-2'>
                               {doctype.documents?.map((document, index) => (
                                   <div key={index} className='bg-grey-50/60 p-2 sm:p-1.5 inline-block whitespace-normal rounded-xl mr-3 cursor-pointer' onClick={() =>
                                       setDocViewerSideBar(document.id)}
                                   >
                                       <div className='doc flex justify-start items-center gap-2 relative sm:gap-1'>
                                           {document.file_type.includes('application') && <img src={require('../assets/Group 11712792291.png')} className='w-12 sm:w-10' />}
                                           {document.file_type.includes('image') && <img src={require('../assets/Group 1171279229.png')} className='w-12 sm:w-10' />}
                                           <div className='w-44 overflow-hidden sm:w-36'>
                                               <p className='text-black text-sm font-semibold sm:text-xs whitespace-nowrap'>{document.file_name.length > 16 ? `${document.file_name.substr(0, 16)}...` : document.file_name}</p>
                                               <p className='text-stalle-500 text-xs font-normal'>{document.file_type}</p>
                                           </div>
                                           <FaRegTrashAlt onClick={(e) => _deleteDoc(e, document.id)} className='opacity-0 text-red text-base absolute right-1 top-1 cursor-pointer sm:opacity-100 sm:text-sm' />
                                       </div>
                                   </div>
                               ))}
                           </div>
                       </>}
                   </div>
               </div>
                ))}


            </div>
        </div>
    )
}

export default ChecklistsApp
