import React, { useContext, useEffect, useState } from 'react'
import { CiSearch } from 'react-icons/ci'
import { GoFilter, GoPlus, GoTrash } from 'react-icons/go'
import { getApplicants } from '../services/getApplicants'
import { Context } from '../state/Provider'
import { Link } from 'react-router-dom'
import { BiSolidPencil } from 'react-icons/bi'
import { IoEyeOutline } from 'react-icons/io5'
import { toast } from 'react-toastify'
import { deleteApplicant } from '../services/deleteApplicant'
import { BsStars } from 'react-icons/bs'
import { Applicant } from '../types'
import MobileViewApplicant from '../components/MobileViewApplicant'

const Applicants: React.FC = () => {
    const { applicants, setRefreshApplicants } = useContext(Context)

    const [localApplicants, setLocalApplicants] = useState<Applicant[] | null>(applicants?.data || null)

    const [loading, setLoading] = useState<boolean>(false)

    const _deleteApplicant = async (id: string) => {
        const confirm = window.confirm('Are you sure you want to delete this application?')
        if (!confirm) return
        setLoading(true)
        try {
            const response = await deleteApplicant(id)
            if (response.status === 204) {
                toast.success("Applicant deleted.")
                setRefreshApplicants(prevState => !prevState)
            }
            else
                toast.error(response.data.detail)
        } catch (error) {
            console.error(error);
        }
        setLoading(false)
    }

    const searchApplicants = (e: React.ChangeEvent<HTMLInputElement>) => {
        const search = e.target.value
        if (search === '') {
            setLocalApplicants(applicants?.data ?? null)
        } else {
            const filteredApplicants = applicants?.data?.filter(applicant => applicant.full_name.toLowerCase().includes(search.toLowerCase()))
            setLocalApplicants(filteredApplicants ?? null)
        }
    }

    useEffect(() => {
        setLocalApplicants(applicants?.data || null)
    }, [applicants])

    return (
        <div className='py-10 px-8 sm:px-4'>
            <div className="flex justify-between items-center sm:flex-col sm:gap-3 sm:items-start">
                <div>
                    <h1 className="text-black font-bold text-2xl flex gap-2 items-center capitalize">Applicants
                    </h1>
                    <p className="text-sm text-stalle-700 font-medium mt-1 sm:text-xs">Here's what's happening with your applicants today</p>
                </div>
                <div className="flex gap-2">

                    <Link to='create' className="rounded-3xl bg-[#6C6DB3] px-3 py-3 text-white font-semibold flex justify-center items-center capitalize gap-1 sm:text-xs  sm:py-2">
                        <GoPlus className='text-white text-xl' />
                        create new applicant
                    </Link>
                </div>
            </div>
            <div className="border-[1px] border-grey-50 rounded-2xl mt-10 sm:border-none sm:mt-4">
                <div className="flex justify-between items-end px-4 py-5 sm:p-0 sm:flex-col sm:items-start sm:gap-2">
                    <div>
                        <h1 className="text-black font-bold text-lg flex gap-2 items-center capitalize">Applicants</h1>
                        <p className="text-sm text-stalle-700 font-medium sm:text-xs">You have currently <b>{applicants?.count}</b> applications</p>
                    </div>

                    <div className="flex gap-2 sm:w-full">
                        <div className='bg-grey-50/40 rounded-3xl w-[300px] border-[1px] border-grey-100 flex items-center gap-1 px-4 p-3 sm:px-3 sm:py-2 sm:w-full '>
                            <CiSearch className='text-black text-xl' />
                            <input onChange={searchApplicants} type='text' placeholder='Search for anything' className='w-full font-medium placeholder:text-black/75 text-black/75 h-full text-sm bg-transparent outline-none sm:text-xs' />
                        </div>
                    </div>
                </div>


                <div className="overflow-x-auto sm:hidden">
                    <table className="min-w-full lg:table">
                        <thead className="bg-[#F1F5F9]">
                            <tr>
                                {/* <th className="px-6 py-3 text-left text-sm text-black font-medium text-gray-500 capitalize tracking-wider flex items-center">
                                    <input type='checkbox' className="mr-2" /> Sr.No
                                </th> */}
                                <th className="px-6 py-3 text-left text-sm text-black font-bold text-gray-500 capitalize tracking-wider">Name</th>
                                <th className="px-6 py-3 text-left text-sm text-black font-bold text-gray-500 capitalize tracking-wider">Email</th>
                                {/* <th className="px-6 py-3 text-left text-sm text-black font-bold text-gray-500 capitalize tracking-wider">Phone</th> */}
                                <th className="px-6 py-3 text-left text-sm text-black font-bold text-gray-500 capitalize tracking-wider w-28">Action</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white">
                            {localApplicants?.map((application, index) => {
                                return (
                                    <tr key={application.id} className="border-b-[1px] border-grey-150/50">
                                        {/* <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 tracking-wider flex items-center">
                                            <input type='checkbox' className="mr-2" />
                                            {index + 1}
                                        </td> */}
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 capitalize">{application.full_name}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 lowercase">{application.email}</td>
                                        {/* <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 capitalize">{application.phone}</td> */}

                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 flex justify-start items-center gap-1.5">
                                            <Link to={`/applicants/${application.id}`} className="flex justify-center items-center p-2 rounded-xl bg-blue-600/15 cursor-pointer">
                                                <BiSolidPencil className='text-blue-600 text-lg' />
                                            </Link>

                                            <button disabled={loading} onClick={() => _deleteApplicant(`${application.id}`)} className="flex justify-center items-center p-2 rounded-xl bg-red/20 cursor-pointer">
                                                <GoTrash className='text-red text-lg' />
                                            </button>
                                        </td>
                                    </tr>
                                )

                            })}

                        </tbody>
                    </table>

                </div>

                <div className='hidden sm:flex flex-col gap-4 mt-4'>
                            {applicants?.data?.map((applicant, index) => {
                                return <MobileViewApplicant applicant={applicant} deleteApplicant={_deleteApplicant}/>
                            })}
                </div>
            </div>
        </div>
    )
}

export default Applicants
