
import axios from 'axios';
import { Applicant, Checklist } from '../types'; // Adjust path to where the types are defined
import { BASE_SERVER } from '../constants';
import { getToken } from '../utils/getToken';


export const getSharedChecklist = async (token:string): Promise<Checklist> => {
    try {
        const response = await axios.get<Checklist>(`${BASE_SERVER}/share/checklist/shared/${token}`, {
            headers: {
                'Authorization': `Bearer ${getToken()}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        // Handle errors or re-throw for the caller to handle
        throw error;
    }
};
