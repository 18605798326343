import React, { useContext, useEffect, useRef, useState } from 'react'
import { Context } from '../state/Provider'
import { RxCross2 } from 'react-icons/rx'
import { Link, useNavigate } from 'react-router-dom'
import { getSingleApplication } from '../services/getSingleApplication'
import { getChecklist } from '../services/getChecklist'
import { Applicant, Checklist } from '../types'
import { getDefaultPfp } from '../utils/getDefaultPfp'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md'
import { PiDownloadSimple, PiShareFatThin } from 'react-icons/pi'
import { downloadApplicationDocs } from '../services/downloadApplicationDocs'
import { toast } from 'react-toastify'
import { generateShareLinkApplication } from '../services/generateShareLinkApplication'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'


interface Application {
    name: string;
    description: string;
    current_stage: string;
    current_status: string;
    country_of_application: string;
    type_of_application: string;
    checklists: Checklist[]
    applicants: Applicant[]
}

function ApplicationDetail() {
    const { setApplicationDetailSideBar, applicationDetailSideBar } = useContext(Context)
    const navigate = useNavigate()
    const [apiCalled, setAppiCalled] = React.useState<boolean>(false)
    const [application, setApplication] = React.useState<Application>({} as Application)
    const [expandAll, setExpandAll] = React.useState<boolean>(false)
    const [opend, setOpened] = React.useState<boolean>(false)
    const [downloading, setDownloading] = React.useState<boolean>(false)

    const getAppCheclists = async (id: string) => {
        try {
            const response: Application = await getSingleApplication(id)
            setApplication(response)
            setAppiCalled(true)
        } catch (error) {
            console.log(error);
        }

    }

    useEffect(() => {
        let id = `${applicationDetailSideBar}`
        getAppCheclists(id)
    }, [applicationDetailSideBar])


    useEffect(() => {
        if (applicationDetailSideBar !== 0)
            setTimeout(() => setOpened(true), 300)
    }, [applicationDetailSideBar])


    return (
        <div className={`h-screen w-full fixed top-0 right-0 bg-black/50 z-[50] transition-all ease-in opacity-0 ${opend ? 'opacity-100' : 'opacity-0'} transition-all ease-in duration-200`}>
            <div className={`hello flex h-screen overflow-y-scroll flex-col justify-start items-start bg-white w-1/3 right-[-1000px] absolute top-0 transition-all ease-in-out duration-300 sm:w-full ${opend ? 'right-[0px]' : 'right-[-1000px]'}`}>
                <div className='p-5 flex justify-between items-center w-full sm:px-4 sm:py-3'>
                <span className='flex gap-2 items-center'>
                        <button onClick={() => {
                            setOpened(false)
                            setTimeout(() => setApplicationDetailSideBar(0), 300)
                        }} className='cursor-pointer bg-grey-50/50 rounded-md border-[1px] border-grey-150/20 p-1.5'>
                            <RxCross2 className='text-black text-xl' />
                        </button>
                        <p className='text-stalle-500/50 text-sm italic'>View Only</p>
                    </span>
                    <div className='flex justify-end items-center gap-2'>
                        <button onClick={async (e) => {
                                        e.stopPropagation()
                                        e.preventDefault()
                                        const response = await generateShareLinkApplication(applicationDetailSideBar)
                                        if (response.status === 200 && 'share_link' in response.data) {
                                            const shareLink = response.data.share_link;
                                            await navigator.clipboard.writeText(shareLink);
                                            toast.success('Link copied');
                                        } else if ('detail' in response.data) {
                                            const errorDetail = response.data.detail;
                                            toast.error(errorDetail || 'Failed to generate share link');
                                        }
                                    }} className='border-[1px] bg-grey-50/50 border-stalle-700/50 rounded-full p-2 text-blue-400 cursor-pointer font-bold text-sm'>
                                         <PiShareFatThin  className='text-black text-lg cursor-pointer' />
                        </button>
                        <button onClick={async() => {
                            setDownloading(true)
                            await downloadApplicationDocs(applicationDetailSideBar)
                            setDownloading(false)
                        }} className='border-[1px] bg-grey-50/50 border-stalle-700/50 rounded-full p-2 text-blue-400 cursor-pointer font-bold text-sm'>
                            {!downloading? 
                            <PiDownloadSimple className='text-black text-lg' />
                            :<AiOutlineLoading3Quarters className='text-black text-lg loading' />}
                        </button>
                        <button onClick={() => {
                            setApplicationDetailSideBar(0)
                            navigate(`/checklists/${applicationDetailSideBar}`)
                        }} className='bg-grey-50/50 px-3 py-1 text-sm rounded-3xl text-blue-600 font-medium border-[1px] border-stalle-700/50'>View More</button>
                    </div>
                </div>

                <hr className='w-full h-[1px] text-stalle-500' />

                {!apiCalled && <div className=' w-full h-full rounded-lg flex justify-center items-center'>
                    <img src='https://cdn.pixabay.com/animation/2023/10/08/03/19/03-19-26-213_512.gif'
                        className='w-16'
                    />
                </div>}

                {apiCalled &&
                    <div className='flex flex-col gap-1 w-full'>
                        <h2 className='text-sm font-bold text-black px-5 mt-5 sm:px-4 uppercase'>Application Details</h2>
                        <div className='flex justify-between items-center px-5 w-full sm:px-4'>
                            <h2 className='text-base font-bold text-black'>{application.name}</h2>
                            <span className='flex gap-1'>
                                <span className="bg-green-400/15 rounded-xl font-bold p-1 px-2 text-green-400 text-sm sm:text-xs">
                                    {application.current_status}
                                </span>
                                <span className="bg-blue-400/15 rounded-xl font-semibold p-1 px-2 text-blue-400 text-sm sm:text-xs">
                                    {application.current_stage}
                                </span>
                            </span>
                        </div>


                        <h2 className='text-stalle-500 font-medium text-sm px-5 mt-2 sm:px-4'>Applicants</h2>
                        <div className='flex flex-wrap justify-start items-start px-5 gap-2 sm:px-4'>
                            {application.applicants.map((applicant, index) => {
                                return (
                                    <div key={index} className='flex gap-1 items-center bg-grey-50/20 px-2 py-1 rounded-2xl border-[1px] border-stalle-700/20'>
                                        <img
                                            src={applicant.profile_image || ''}
                                            alt="profile"
                                            onError={(e: any) => { e.target.src = getDefaultPfp(); }}
                                            className="w-5 h-5 rounded-full"
                                        />
                                        <p className='text-stalle-500/85 text-sm font-medium'>{applicant.full_name}</p>
                                    </div>
                                )
                            })}
                        </div>
                        <hr className='w-full h-[1px] text-stalle-500/30 mt-3' />
                        <div className='flex flex-col gap-3 px-5 mt-2 mb-5 sm:px-4'>
                            <div className='flex justify-between items-center'>
                                <h2 className='text-stalle-500 font-medium text-sm'>Checklists</h2>
                                <button onClick={() => setExpandAll(!expandAll)} className='capitalize text-xs text-stalle-700 bg-grey-50/40 px-2 py-1 rounded-2xl border-[1px] border-stalle-700/20 sm:text-xs'>
                                    expand all
                                </button>
                            </div>

                            {application.checklists.map((checklist, index) => (
                                <ChecklistAccordion key={index} checklist={checklist} expandAll={expandAll} />
                            ))}
                        </div>
                    </div>}


            </div>

        </div>
    )
}

export default ApplicationDetail

interface ChecklistAccordianProps {
    checklist: Checklist
    expandAll: boolean

}

const ChecklistAccordion: React.FC<ChecklistAccordianProps> = ({ checklist, expandAll }) => {
    const totalDocTypes: number = Number(checklist.doctypes.length) ?? 0;
    const filledDocTypes: number = Number(checklist.doctypes.filter(docType => docType.documents.length > 0).length) ?? 0
    const progress: number = totalDocTypes > 0 ? Number((filledDocTypes / totalDocTypes) * 100) : 0;

    const { setDocViewerSideBar } = useContext(Context)

    const [expand, setExpand] = React.useState<boolean>(false)
    const contentRef = useRef<HTMLDivElement>(null);
    const [contentHeight, setContentHeight] = useState<string>('0px')

    useEffect(() => {
        if (contentRef.current) {
            setContentHeight(expand ? `${contentRef.current.scrollHeight}px` : '0px');
        }
    }, [expand])

    useEffect(() => {
        setExpand(expandAll)
    }, [expandAll])

    return (
        <div className='border-[1px] border-stalle-700 rounded-xl overflow-hidden bg-grey-100/50'>
            <div className='flex justify-between items-center p-3 cursor-pointer' onClick={() => setExpand(!expand)}>
                <h2 className='text-stalle-500 font-bold text-sm'>{checklist.name}</h2>
                <div className='flex justify-end items-center'>
                    <span className='text-sm text-stalle-700 font-medium flex gap-1 justify-center items-center sm:text-xs'>
                        {progress.toFixed(0)}% complete
                        <div className='w-6 flex justify-center items-center sm:w-4'>
                            <CircularProgressbar
                                value={progress}
                                text={``}
                                styles={buildStyles({
                                    rotation: 0.25,
                                    strokeLinecap: 'butt',
                                    pathTransitionDuration: 0.5,
                                    pathColor: `#059669`,
                                    trailColor: '#E2E8F0',
                                })}
                            />
                        </div>
                        <MdOutlineKeyboardArrowDown className={`text-stalle-500 text-xl transition-all ease-in duration-100 ${expand ? 'rotate-180' : ''}`} />
                    </span>
                </div>
            </div>

            <div
                ref={contentRef}
                style={{ height: contentHeight }}
                className='transition-all duration-300 ease-in-out overflow-hidden'
            >
                {checklist.doctypes.length === 0 && <p className='text-red text-xs p-3'>No doctypes created</p>}
                {checklist.doctypes?.map((doctype, index) => (
                    <div className='flex flex-col gap-8 px-3 pb-3'>
                        <div className='flex flex-col gap-1'>
                            <h2 className='text-stalle-500 font-medium text-sm sm:text-xs'>{doctype.name}</h2>
                            <div className='flex gap-4 flex-wrap'>
                                {doctype.documents.length === 0 && <p className='text-red text-xs sm:text-xs'>No documents uploaded</p>}
                                {doctype.documents?.map((document, index) => (
                                    <div key={index} className='bg-grey-50/60 p-2 inline-block whitespace-normal rounded-xl cursor-pointer w-[47%]' onClick={() => setDocViewerSideBar(document.id)}
                                    >
                                        <div className='flex justify-start items-center gap-2'>
                                            {document.file_type.includes('application') && <img src={require('../assets/Group 11712792291.png')} className='w-10' />}
                                            {document.file_type.includes('image') && <img src={require('../assets/Group 1171279229.png')} className='w-10' />}
                                            <div className='w-auto overflow-hidden'>
                                                <p className='text-black text-sm font-semibold sm:text-xs whitespace-nowrap'>{document.file_name.length > 15 ? `${document.file_name.substr(0, 15)}...`: document.file_name}</p>
                                                <p className='text-stalle-500 text-xs font-normal sm:text-[10px]'>{document.file_type}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

