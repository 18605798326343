import { NavLink } from "react-router-dom"
import { HiViewGridAdd } from "react-icons/hi";
import React, { useEffect, useRef } from "react";
import { MdOutlineKeyboardDoubleArrowLeft } from "react-icons/md";
import { LuFileText } from "react-icons/lu";
import { HiOutlineClipboardDocument } from "react-icons/hi2";
import { GoCheckbox } from "react-icons/go";
import { FaRegUser } from "react-icons/fa6";
import { FiUsers } from "react-icons/fi";
import { IoIosLogOut } from "react-icons/io";

interface props {
    sidebar: boolean;
    setSidebar: React.Dispatch<React.SetStateAction<boolean>>;
    mobDrawer: boolean; 
    setMobDrawer: React.Dispatch<React.SetStateAction<boolean>>;
}

const Sidebar: React.FC<props> = ({ sidebar, setSidebar, mobDrawer, setMobDrawer }) => {

    const menuRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
                setMobDrawer(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [menuRef]);

    return (
        <div ref={menuRef} className={`fixed ${sidebar? 'left-0' : 'left-[-500px]'} top-16 min-h-screen pl-6 z-40 transition-all ease-in duration-75 ${mobDrawer? 'sm:left-0' : 'sm:left-[-500px]'} sm:p-0 sm:top-12`}>
            <div className='w-[217px] sm:w-[220px] pl-2 flex flex-col gap-1 py-6 sm:bg-white sm:shadow-xl sm:h-screen sm:pl-0 sm:border-r-[1px] sm:border-black/15 sm:py-4'>

                <h2 className='text-sm text-bold text-grey-150 sm:pl-3'>Main Menu</h2>
                <nav className="flex flex-col gap-0 sidebar">
                    <NavLink to='/dashboard' onClick={()=>setMobDrawer(false)} className="py-2 flex justify-start items-center gap-1 text-lg text-grey-500 transition-all ease in duration-300  sm:pl-3 sm:text-sm">
                        <HiViewGridAdd className="text-2xl text-grey-500" />Dashboard
                    </NavLink>
                    <NavLink to='/applications' onClick={()=>setMobDrawer(false)} className="py-2  flex justify-start items-center gap-1 text-lg text-grey-500 transition-all ease in duration-300 sm:pl-3 sm:text-sm">
                        <LuFileText className="text-2xl text-grey-500" />Applications
                    </NavLink>
                    <NavLink to='/checklists' onClick={()=>setMobDrawer(false)} className="py-2  flex justify-start items-center gap-1 text-lg text-grey-500 transition-all ease in duration-300 sm:pl-3 sm:text-sm">
                        <GoCheckbox className="text-2xl text-grey-500" />Checklists
                    </NavLink>
                    <NavLink to='/applicants' onClick={()=>setMobDrawer(false)} className="py-2  flex justify-start items-center gap-1 text-lg text-grey-500 transition-all ease in duration-300 sm:pl-3 sm:text-sm">
                        <HiOutlineClipboardDocument className="text-2xl text-grey-500" />Applicants
                    </NavLink>
                    {JSON.parse(localStorage.getItem('user') || "{}")?.is_superuser && <NavLink to='/admin' onClick={()=>setMobDrawer(false)} className="py-2  flex justify-start items-center gap-1 text-lg text-grey-500 transition-all ease in duration-300 sm:pl-3 sm:text-sm">
                        <FiUsers className="text-2xl text-grey-500" />Admin
                    </NavLink>}
                </nav>

                <h2 className='text-sm text-bold text-grey-150 mt-4 sm:pl-3'>Settings</h2>
                <nav className="flex flex-col gap-0 sidebar">
                    <NavLink to='/personal-info' onClick={()=>setMobDrawer(false)} className="py-2  flex justify-start items-center gap-1 text-lg text-grey-500 transition-all ease in duration-300 sm:pl-3 sm:text-sm">
                        <FaRegUser className="text-xl text-grey-500" />Personal info
                    </NavLink>
                </nav>

                <button onClick={()=>{
                    localStorage.removeItem('token')
                    window.location.href = '/'
                }}
                 className="py-2 flex justify-start items-center gap-1 text-lg text-grey-500 transition-all ease in duration-300 absolute bottom-20 sm:pl-3 sm:text-sm sm:bottom-28">
                    <IoIosLogOut className="text-2xl text-grey-500" />Log Out
                </button>

                <hr className={`w-[1.5px] h-screen bg-black/15 fixed top-0 ${sidebar? 'left-[240px]': 'left-[-500px]'} transition-all ease-in duration-75 sm:hidden`}/>

                <MdOutlineKeyboardDoubleArrowLeft onClick={() => setSidebar(!sidebar)} className={`text-[38px] transition-all ease-in duration-75 bg-black rounded-full p-1.5 text-white fixed top-24 ${sidebar? 'left-[220px]' : 'rotate-180 left-[-10px]'} cursor-pointer border-[2px] border-white sm:hidden`} />
            </div>
        </div>
    )
}

export default Sidebar
