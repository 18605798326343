import axios, { AxiosResponse } from 'axios';
import { getToken } from '../utils/getToken';
import { BASE_SERVER } from '../constants';

// Define the failure response structure
interface FailureResponse {
    detail?: string;
    message?: string;
    // Add other fields if the API provides more details
}

// Function to delete an application
export const deleteUser = async (
    id: string
): Promise<AxiosResponse<FailureResponse>> => {
    try {
        const response: AxiosResponse<FailureResponse> = await axios.delete(`${BASE_SERVER}/users/${id}`, {
            headers: {
                'Authorization': `Bearer ${getToken()}`,
                'Accept': 'application/json'
            }
        });
        return response;
    } catch (error: any) {
        if (error.response) {
            return error.response;
        } else if (error.request) {
            return {
                data: { detail: 'No response from server' },
                status: 0,
                statusText: '',
                headers: {},
                config: {},
                request: error.request
            } as AxiosResponse<FailureResponse>;
        } else {
            return {
                data: { detail: error.message },
                status: 0,
                statusText: '',
                headers: {},
                config: {},
                request: null
            } as AxiosResponse<FailureResponse>;
        }
    }
};
