import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import Landing from './pages/Landing'
import Login from './pages/Login'
import Logging from './pages/Logging';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Mainlayout from './layouts/Mainlayout';
import Applications from './pages/Applications';
import Applicants from './pages/Applicants';
import PersonalInfo from './pages/PersonalInfo';
import Application from './pages/Application';
import Checklists from './pages/Checklists';
import Provider from './state/Provider';
import Applicant from './pages/Applicant';
import ChecklistsApp from './pages/ChecklistsApp';
import Checklist from './pages/Checklist';
import Users from './pages/Users';
import Dashboard from './pages/Dashboard';
import SharedCheclist from './pages/SharedCheclist';
import SharedApplication from './pages/SharedApplication';
import User from './pages/User';
import { useState } from 'react';
import UploadFilesDocType from './components/UploadFilesDocType';

function App() {
  const token = localStorage.getItem('token')
  const [uploadSharedDocument, setuploadSharedDocument] = useState<boolean>(false)

  return (
    <BrowserRouter>
      {token ? (
        // Protected routes
        <Provider>
        <Mainlayout>
          <Routes>
            <Route index element={<Navigate to='/dashboard'/>} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/applications" element={<Outlet />}>
              <Route index element={<Applications />} />
              <Route path='create' element={<Application />} />
              <Route path=':id' element={<Application />} />
            </Route>
            <Route path="/applicants" element={<Outlet />}>
              <Route index element={<Applicants />} />
              <Route path='create' element={<Applicant />} />
              <Route path=':id' element={<Applicant />} />
            </Route>
            <Route path="/admin" element={<Outlet />}>
              <Route index element={<Users />} />
              <Route path='create' element={<User />} />
              <Route path=':id' element={<User />} />
            </Route>
            <Route path="/checklists" element={<Outlet />}>
              <Route index element={<Navigate to='/checklists/create' />} />
              <Route path='create' element={<Checklist />} />
              <Route path=':id' element={<ChecklistsApp />} />
              <Route path=':id/create' element={<Checklist />} />
            </Route>
            <Route path="/personal-info" element={<PersonalInfo />} />
            {/* <Route path='*' element={<Navigate to='/dashboard' />} /> */}
          </Routes>
        </Mainlayout>
        </Provider>

      ) : (
        // Public routes
        <>
        <Routes>
          <Route index element={<Landing />} />
          <Route path="/googleauth" element={<Logging />} />
          <Route path="/login" element={<Login />} />
          <Route path="/sign-up" element={<Login />} />
          <Route path="/shared-checklist/:token" element={<SharedCheclist uploadSharedDocument={uploadSharedDocument} setuploadSharedDocument={setuploadSharedDocument}/>} />
          <Route path="/shared-application/:token" element={<SharedCheclist uploadSharedDocument={uploadSharedDocument} setuploadSharedDocument={setuploadSharedDocument}/>} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        {uploadSharedDocument && <UploadFilesDocType setuploadSharedDocument={setuploadSharedDocument}/>}
        </>
      )}
      <ToastContainer transition={Slide} position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light" />
    </BrowserRouter>
  )



}

export default App
