import axios from 'axios';
import { BASE_SERVER } from '../constants';
import { getToken } from '../utils/getToken';

export const downloadDocktype = async (id: number): Promise<void> => {
    try {
        let token = `Bearer ${getToken()}`
        
        const response = await axios.get(`${BASE_SERVER}/download_documents/for_doctype/${id}`, {
            responseType: 'blob',
            headers: {
                'Authorization': token,  // Include any required headers, such as authorization
            }
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `${id}_documents.zip`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);


    } catch (error) {
        console.error('There was an error downloading the file:', error);
    }
};
