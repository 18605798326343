import axios, { AxiosResponse } from 'axios';
import { getToken } from '../utils/getToken';
import { BASE_SERVER } from '../constants';

// Define the application request structure
interface Applicant {
    first_name: string;
    last_name: string;
    email: string;
    full_name?: string;
}


// Define the failure response structure
interface FailureResponse {
    detail?: string;
    // Add other fields if the API provides more details
}

// Function to create an application
export const updateApplicant = async ( id: string,
    application : Applicant
): Promise<AxiosResponse<FailureResponse>> => {


    try {
        const response: AxiosResponse<FailureResponse> = await axios.put(`${BASE_SERVER}/applicants/${id}`, application, {
            headers: {
                'Authorization': `Bearer ${getToken()}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        return response;
    } catch (error: any) {
        if (error.response) {
            return error.response;
        } else if (error.request) {
            return {
                data: { detail: 'No response from server' },
                status: 0,
                statusText: '',
                headers: {},
                config: {},
                request: error.request
            } as AxiosResponse<FailureResponse>;
        } else {
            return {
                data: { detail: error.message },
                status: 0,
                statusText: '',
                headers: {},
                config: {},
                request: null
            } as AxiosResponse<FailureResponse>;
        }
    }
};
