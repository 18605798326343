import React, { useContext } from 'react'
import { IoArrowBack } from 'react-icons/io5'
import { useNavigate, useParams } from 'react-router-dom'
import { Context } from '../state/Provider'
import Input from '../components/Input'
import { createChecklist } from '../services/createChecklist'
import { toast } from 'react-toastify'
import { createChecklistWithExistingApplicant } from '../services/createChecklistWithExistingApplicant'

interface Checklist {
    name: string
    due_date: string;
    share_link: string;
    current_status: string;
    application_id: number;
    applicant_id: number
}
interface Applicant {
    first_name: string;
    last_name: string;
    email: string;
    full_name: string;
}

function Checklist() {
    const { applications, applicants } = useContext(Context)
    const { id, checlist_id } = useParams()
    const navigate = useNavigate()
    const [loading, setLoading] = React.useState<boolean>(false)
    const [newApplicant, setNewApplicant] = React.useState<boolean>(true)

    const [checklist, setChecklist] = React.useState<Checklist>({
        name: '',
        due_date: '',
        share_link: '',
        current_status: '',
        application_id: 0,
        applicant_id: 0
    })
    const [applicant, setApplicant] = React.useState<Applicant>({
        first_name: '',
        last_name: '',
        full_name: '',
        email: ''
    })



    const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setChecklist({ ...checklist, [e.target.name]: e.target.value })
    }
    const onChangeApplicant = (e: React.ChangeEvent<HTMLSelectElement>) => {
        if (e.target.name === 'existing') {
            const existingApplicant = applicants?.data.find((app: any) => app.id === parseInt(e.target.value))
            if (existingApplicant)
                setChecklist({ ...checklist, applicant_id: existingApplicant.id })
            return
        }
        setApplicant({ ...applicant, [e.target.name]: e.target.value, full_name: applicant.first_name + ' ' + applicant.last_name })
    }

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setLoading(true)
        try {
            let response
            if (newApplicant)
                response = await createChecklist(checklist, applicant)
            else
                response = await createChecklistWithExistingApplicant(checklist, applicant)
            if (response.status === 200) {
                toast.success('Checklist created successfully')
                navigate(-1)
            }
            else
                toast.error(response.data.detail)
        } catch (error) {
            console.log(error);
        }
        setLoading(false)
    }


    return (
        <div className='flex flex-col gap-4 justify-start items-start py-10 px-8 sm:px-4'>
            {id && <button onClick={() => navigate(-1)} className='flex items-center gap-1 bg-grey-50 rounded-3xl px-2 py-2 text-black cursor-pointer text-sm'>
                <IoArrowBack className='text-black text-md' />
                Back
            </button>}
            <div>
                <h1 className="text-black font-bold text-lg flex gap-2 items-center capitalize">New Checklist</h1>
                <p className="text-sm text-stalle-700 font-medium">You are creating new checklist</p>
            </div>

            <form onSubmit={onSubmit} className='flex flex-col gap-4 mt-2 justify-start items-start w-full'>
                <div className='flex gap-4 mt-2 justify-start items-start w-full flex-wrap'>
                    <div className='flex flex-col gap-1 relative w-[32.4%] sm:w-full'>
                        <label className='text-black text-sm font-bold sm:text-xs'>Select Application</label>
                        <select required name='application_id' onChange={onChange} className="border-[2px] border-grey-50/75 focus:border-black/50 transition-all ease-in duration-150 rounded-lg px-3 py-0 h-[56px] font-medium placeholder:text-black/50 text-black text-sm outline-none sm:text-xs sm:py-4 sm:h-12">
                            <option value={''}>Select</option>
                            {applications?.data?.map((app: any, index: number) => (
                                <option key={index} value={app.id}>{app.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className='flex flex-col gap-1 relative w-[32.2%]  sm:w-full'>
                        <label className='text-black text-sm font-bold sm:text-xs'>Status</label>
                        <select required name='current_status' onChange={onChange} className="border-[2px] border-grey-50/75 focus:border-black/50 transition-all ease-in duration-150 rounded-lg px-3 py-0 h-[56px] font-medium placeholder:text-black/50 text-black text-sm outline-none sm:text-xs sm:py-4 sm:h-12">
                            <option value={''}>Select Type</option>
                            <option value={'Not Started'}>Not Started</option>
                            <option value={'In Progress'}>In Progress</option>
                            <option value={'Completed'}>Completed</option>
                            <option value={'Issues'}>Issues</option>
                        </select>
                    </div>
                    <span className='w-[32.5%] sm:w-full'>
                        <Input type='text' onChange={onChange} value={''} placeholder='Type here' required={true} disabled={false} label={"Name"} name='name' readOnly={false} />
                    </span>
                    <span className='w-[32.4%] sm:w-full'>
                        <Input type='date' onChange={onChange} value={''} placeholder='' required={true} disabled={false} label={"Due Date"} name='due_date' readOnly={false} />
                    </span>
                </div>



                <h1 className="text-black font-bold text-base mt-2 -mb-2">Applicant</h1>
                <div className='flex flex-col'>
                    <div className='flex flex-col gap-1'>
                        <span className='flex gap-1 items-center text-black text-sm font-medium sm:text-xs'>
                            <input type='radio' name='hello' checked={!newApplicant} onChange={() => setNewApplicant(false)} /> Existing Applicants
                        </span>
                        <span className='flex gap-1 items-center text-black text-sm font-medium sm:text-xs'>
                            <input type='radio' name='hello' checked={newApplicant} onChange={() => setNewApplicant(true)} /> New Applicant
                        </span>
                    </div>
                </div>


                {newApplicant ? <div className='flex gap-3 w-full relative sm:flex-col'>
                    <Input type='text' onChange={onChangeApplicant} value={''} placeholder='Type here' required={true} disabled={false} label={"First Name"} name='first_name' readOnly={false} />
                    <Input type='text' onChange={onChangeApplicant} value={''} placeholder='Type here' required={true} disabled={false} label={"Last Name"} name='last_name' readOnly={false} />
                    <Input type='email' onChange={onChangeApplicant} value={''} placeholder='Type here' required={true} disabled={false} label={"Email"} name='email' readOnly={false} />

                </div>
                    :
                    <div className='flex flex-col gap-1 relative w-1/3 sm:w-full'>
                        <label className='text-black text-sm font-bold sm:text-xs'>Select Applicant</label>
                        <select required name='existing' onChange={onChangeApplicant} className="border-[2px] border-grey-50/75 focus:border-black/50 transition-all ease-in duration-150 rounded-lg px-3 py-0 h-[56px] font-medium placeholder:text-black/50 text-black text-sm outline-none sm:text-xs sm:py-4 sm:h-12">
                            <option value={''}>Select</option>
                            {applicants?.data?.map((app: any, index: number) => (
                                <option key={index} value={app.id}>{app.full_name}</option>
                            ))}
                        </select>
                    </div>
                }

                <button disabled={loading} className='disabled:opacity-75 disabled:cursor-wait rounded-3xl border-[2px] border-blue-600 px-6 py-3 sm:px-3 sm:py-2 sm:text-xs  text-white bg-blue-600 font-bold flex text-sm justify-center items-center gap-3'>
                    {checlist_id ? 'Update' : 'Create'}
                </button>
            </form>

        </div>
    )
}

export default Checklist
