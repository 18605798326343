import { useContext, useEffect, useState } from "react"
import { Context } from "../state/Provider"
import { RxCross2 } from "react-icons/rx"
import { getDocs } from "../services/getDocs"


function DocViewer() {
    const { docViewerSideBar, setDocViewerSideBar } = useContext(Context)
    const [accessUrl, setAccessUrl] = useState<string | null>(null);
    const [file_type, setFileType] = useState<string | null>(null);
    const [opend, setOpened] = useState<boolean>(false)

    const getData = async () => {
        try {
            const x = await getDocs(docViewerSideBar)
            setAccessUrl(x.access_url)
            setFileType(x.file_type)

        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {

        getData()
    }, [docViewerSideBar])

    useEffect(() => {
        if (docViewerSideBar)
            setTimeout(() => setOpened(true), 300)
    }, [docViewerSideBar])

    return (
        <div className={`w-full h-screen fixed top-0 right-0 bg-black/50 z-[50] opacity-0 ${opend ? 'opacity-100' : 'opacity-0'} transition-all ease-in duration-200`}>
            <div className={`flex flex-col py-5 px-5 sm:px-4 sm:py-3 justify-start items-start gap-5 bg-white w-1/2 h-screen absolute top-0 right-[-1000px] transition-all ease-in-out duration-300 overflow-y-scroll sm:w-full  ${opend ? 'right-[0px]' : 'right-[-1000px]'}`}>
                <button onClick={() => {
                       setOpened(false)
                       setTimeout(() => setDocViewerSideBar(false), 300)
                    }} className='cursor-pointer bg-grey-50/50 rounded-md border-[1px] border-grey-150/20 p-1.5'>
                    <RxCross2 className='text-black text-xl' />
                </button>

                {!accessUrl &&
                    <div className=' w-full h-full rounded-lg flex justify-center items-center'>
                        <img src='https://cdn.pixabay.com/animation/2023/10/08/03/19/03-19-26-213_512.gif'
                            className='w-16'
                        />
                    </div>

                }
                {accessUrl && <div className="flex justify-center items-start w-full">
                    {file_type?.startsWith('image') ?
                        <img src={accessUrl} alt="attachment" className="bg-"/>
                        :
                        <div className="w-full h-[600px]">
                            <iframe src={accessUrl} style={{ width: '100%', height: 600 }} />
                        </div>
                    }
                </div>}


            </div>
        </div>
    )
}

export default DocViewer
