import { HiOutlineDotsVertical } from "react-icons/hi"
import { Application } from "../types"
import { useContext, useEffect, useRef, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { BiSolidPencil } from "react-icons/bi"
import { IoEyeOutline } from "react-icons/io5"
import { Context } from "../state/Provider"
import { GoTrash } from "react-icons/go"

interface MobileViewProps {
    application: Application
    deleteApp: (id: string) => void
}


const MobileViewApplication: React.FC<MobileViewProps> = ({ application, deleteApp }) => {
    const [menu, setMenu] = useState<boolean>(false)
    const { setApplicationDetailSideBar } = useContext(Context)
    const menuRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate()

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
                setMenu(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [menuRef]);
    return (
        <div onClick={() => navigate(`/checklists/${application.id}`)} className="p-2 border-[1px] border-grey-50 rounded-lg flex flex-col gap-2 relative">
            <div className="flex items-center justify-end gap-1.5">
                <span className="bg-blue-400/15 rounded-xl font-semibold p-1 px-2 text-blue-400 text-xs">
                    {application.current_stage}
                </span>
                <span className="bg-green-400/15 rounded-xl font-bold p-1 px-2 text-green-400 text-xs">
                    {application.current_status}
                </span>
                <span onClick={(e) =>{e.stopPropagation(); setMenu(!menu)}} className="font-bold p-1 border-[1px] border-grey-50  rounded-full">
                    <HiOutlineDotsVertical className='text-black text-base' />
                </span>

                {menu && <div ref={menuRef} className="absolute top-9 right-3 bg-white border-stalle-500/30 shadow-md border-[1px] p-2 rounded-lg w-36 flex flex-col gap-1.5 z-30">
                    <div onClick={(e) =>{e.stopPropagation(); navigate(`/applications/${application.id}`)}}  className="flex justify-start items-center gap-2">
                        <span className="flex justify-center items-center p-1.5 rounded-xl bg-blue-600/15 cursor-pointer">
                            <BiSolidPencil className='text-blue-600 text-md' />
                        </span>
                        <p className="text-xs text-black font-normal">Edit</p>
                    </div>
                    <div onClick={(e) => {e.stopPropagation(); setApplicationDetailSideBar(application.id)}} className="flex justify-start items-center gap-2">
                        <span className="flex justify-center items-center p-1.5 rounded-xl bg-green-400/15 cursor-pointer">
                            <IoEyeOutline className='text-green-400 text-md' />
                        </span>
                        <p className="text-xs text-black font-normal">View</p>
                    </div>
                    <div onClick={(e) => {e.stopPropagation(); deleteApp(String(application.id))}} className="flex justify-start items-center gap-2">
                        <span className="flex justify-center items-center p-1.5 rounded-xl bg-red/20 cursor-pointer">
                            <GoTrash className='text-red text-sm' />
                        </span>
                        <p className="text-xs text-black font-normal">Delete</p>
                    </div>
                </div>}
            </div>
            <div className="flex justify-between items-start gap-1 mt-2">
                <span>
                    <h1 className="font-bold text-stalle-500 text-xs uppercase">Name</h1>
                    <h1 className="font-normal text-black text-xs capitalize">{application.name}</h1>
                    <h1 className="font-normal text-stalle-500 text-xs italic mt-2 capitalize">{application.updated_date.replace('T', ' ').substr(0, 16)}</h1>
                </span>
                <span>
                    <h1 className="font-bold text-stalle-500 text-xs uppercase">Type of Application</h1>
                    <h1 className="font-normal text-black text-xs capitalize">{application.type_of_application}</h1>
                </span>
            </div>
        </div>
    )
}


export default MobileViewApplication