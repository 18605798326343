import React, { useCallback, useContext, useEffect } from 'react'
import { Context } from '../state/Provider'
import { RxCross2 } from 'react-icons/rx'
import { useDropzone } from 'react-dropzone'
import { uploadDocs } from '../services/uploadDocs'
import { documents } from '../services/documets'
import { toast } from 'react-toastify'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'
import { useParams } from 'react-router-dom'
import { uploadDocsForShared } from '../services/uploadDocsForShared'

interface Props {
    setuploadSharedDocument? : React.Dispatch<React.SetStateAction<boolean>>
}

const UploadFilesDocType: React.FC<Props> = ({ setuploadSharedDocument }) => {
    const { setuploadFilesCreateDocTypePopup } = useContext(Context)
    const [files, setFiles] = React.useState<File[]>([])
    const [loading, setLoading] = React.useState<boolean>(false)
    const [uploading, setUploading] = React.useState<boolean>(false)
    const onDrop = useCallback((acceptedFiles: any) => {
        setFiles([...files, ...acceptedFiles])
    }, [])
    const { token } = useParams()
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop, accept: {
            'application/pdf': ['.pdf'],             // PDF
            'image/png': ['.png'],                   // PNG
            'image/jpeg': ['.jpg', '.jpeg']          // JPG
        }
    })

    const removeFile = (index: number) => {
        const newFiles = [...files]
        newFiles.splice(index, 1)
        setFiles(newFiles)
    }


    const uploadFiles = async () => {
        setLoading(true)
        setUploading(true)
        const formData = new FormData()
        files.forEach((file) => {
            formData.append('files', file)
        })
        const doctype_id = localStorage.getItem('doctype_id')
        const checklist_id = localStorage.getItem('checklist_id')
        const application_id = localStorage.getItem('application_id')
        const shared_token = localStorage.getItem('shared_token')

        try {
            let response 
            if(window.location.pathname.includes('/shared')){
                response = await uploadDocsForShared(doctype_id!, shared_token ?? '', formData)
                setuploadSharedDocument && setuploadSharedDocument(false)
            }
            else{
                response = await uploadDocs(doctype_id!, formData)
            }
            if (response.status === 200 && 'data' in response.data) {
                toast.success('Files uploaded successfully')
                setuploadFilesCreateDocTypePopup && setuploadFilesCreateDocTypePopup(false)
                setuploadSharedDocument && setuploadSharedDocument(false)
            }
            else
                if ("detail" in response.data)
                    toast.error(response.data.detail || 'Failed to upload files')
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
        setUploading(false)
    }


    useEffect(() => {
        return () => {
            localStorage.removeItem('doctype_id')
            localStorage.removeItem('checklist_id')
            localStorage.removeItem('application_id')
            localStorage.removeItem('allowed_size')
            localStorage.removeItem('shared_token')
        }
    }, [])
    return (
        <div className={`h-screen w-full flex justify-center items-center fixed top-0 right-0 bg-black/50 z-[50] transition-all ease-in duration-300`}>
            <div className='flex w-2/5 bg-white p-4 rounded-2xl flex-col gap-2 overflow-hidden sm:w-[90%]'>
                <div className='flex justify-between items-center w-full'>
                    <h2 className='text-black text-lg font-bold'>Upload Documents</h2>
                    <button disabled={loading} onClick={() => {window.location.pathname.includes('/shared') ? (setuploadSharedDocument && setuploadSharedDocument(false)) : setuploadFilesCreateDocTypePopup(false) }} className='cursor-pointer bg-grey-50/50 rounded-md border-[1px] border-grey-150/20 p-1.5'>
                        <RxCross2 className='text-black text-xl' />
                    </button>
                </div>

                <div className='flex flex-col gap-2 h-52 mt-2 sm:mt-0'>
                    <div className='flex flex-wrap gap-1'>

                        {files.map((file, index) => (
                            <div className='bg-grey-50/60 p-2 inline-block whitespace-normal rounded-xl w-[49%]'>
                                <div className='flex justify-start items-center gap-2 relative'>
                                    {file.type.includes('application') && <img src={require('../assets/Group 11712792291.png')} className='w-12 sm:w-10' />}
                                    {file.type.includes('image') && <img src={require('../assets/Group 1171279229.png')} className='w-12 sm:w-10' />}
                                    <div className='w-44'>
                                        <p className='text-black text-xs font-semibold'>{file.name.substr(0, 25)}</p>
                                        <p className='text-stalle-500 text-xs font-normal'>
                                            {file.size >= 1048576 ? `${(Math.ceil(file.size / (1024 * 1024))).toFixed(0)} MB` : `${(Math.ceil(file.size / 1024)).toFixed(0)} KB`}
                                        </p>
                                    </div>
                                    <RxCross2 className='text-red text-xl absolute top-0 right-0 cursor-pointer sm:-top-1 sm:left-0' onClick={() => removeFile(index)} />
                                </div>
                            </div>
                        ))}
                    </div>
                    <div {...getRootProps()} className='border-[1px] border-blue-400 border-dashed rounded-2xl h-full flex flex-col justify-center items-center cursor-pointer'>
                        <input {...getInputProps()} />
                        <p className='text-black text-sm text-center'><span className='font-bold text-blue-600 underline sm:text-xs'>Click to upload</span><br></br> or <br></br> drag and drop</p>
                    </div>
                </div>
                <div className='flex justify-between items-center sm:flex-col sm:items-start'>
                    <p className='text-xs font-normal text-stalle-500'>Supported formats: DOC, PDF, XLS, PNG, JPG</p>
                    <p className='text-xs font-normal text-stalle-500'>Maximum Size: {localStorage.getItem('allowed_size')} MB</p>
                </div>
                <div className='flex justify-end items-center gap-2'>
                    <button disabled={loading} onClick={() => setuploadFilesCreateDocTypePopup(false)} className='text-black text-sm bg-grey-50/50 border-[1px] border-stalle-700/50 rounded-3xl px-4 py-2 sm:text-xs'>
                        Cancel
                    </button>
                    <button disabled={loading} onClick={uploadFiles} className='text-white text-sm bg-blue-600 border-[1px] border-blue-600/50 rounded-3xl px-4 py-2 disabled:cursor-wait disabled:opacity-95 sm:text-xs'>
                        {!uploading ? 'Upload': <AiOutlineLoading3Quarters className='text-white text-xl loading' />}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default UploadFilesDocType
