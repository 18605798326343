import React, { useState } from 'react'
import Input from '../components/Input'
import { toast } from 'react-toastify';
import { updatePassword } from '../services/updatePassword';
import { updateMe } from '../services/updateMe';

interface Password {
    current_password: string;
    new_password: string;
    confirm_new_password: string;
}
interface MyData {
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
}

const PersonalInfo: React.FC = () => {

    const [submitBtn, setSubmitBtn] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const user = JSON.parse(localStorage.getItem('user') || "{}")
    const [myData, setMyData] = useState<MyData>({
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        phone: user.phone,
    })
    const [password, setPassword] = useState<Password>({
        current_password: '',
        new_password: '',
        confirm_new_password: ''
    })
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSubmitBtn(true)
        setMyData({ ...myData, [e.target.name]: e.target.value })
    }
    const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword({ ...password, [e.target.name]: e.target.value })
    }

    const onSubmitProfile = async(e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setLoading(true)
        try {
            const response = await updateMe(myData.first_name, myData.last_name, myData.first_name+ " "+ myData.last_name, myData.email, myData.phone)
            if (response.status === 200) {
                localStorage.setItem('user', JSON.stringify(response.data))
                toast.success('Info Updated')
            } else {
                toast.error(response.data.detail);
            }
        } catch (error) {
            console.log(error);
        }
        setLoading(false)
    }
    const onSubmitPass = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setLoading(true)
        try {
            if (password.new_password !== password.confirm_new_password) {
                toast.error(`New and confirm passwords doesn't match`)
                setLoading(false);
                return
            }

            const resonse = await updatePassword(password.current_password, password.new_password)
            if (resonse.status === 200) {
                (document.getElementById('confirm_new_password') as HTMLInputElement).value = '';
                (document.getElementById('new_password') as HTMLInputElement).value = '';
                (document.getElementById('current_password') as HTMLInputElement).value = '';
                toast.success(resonse.data.message)
                setPassword({
                    confirm_new_password: '',
                    new_password: '',
                    current_password: ''
                })

            }
            else
                toast.error(resonse.data.detail)

        } catch (error) {
            console.log(error);

        }
        setLoading(false)
    }
    return (
        <div className='py-10 px-8 sm:px-4'>

            <div className="border-[1px] border-grey-50 rounded-2xl px-4 py-5 sm:border-none sm:p-0">
                <div>
                    <h1 className="text-black font-bold text-lg flex gap-2 items-center capitalize">Personal Info</h1>
                    <p className="text-sm text-stalle-700 font-medium sm:text-xs">Provide personal details and how we can reach you</p>
                </div>
                <form onSubmit={onSubmitProfile} className='flex flex-col gap-4 mt-6 justify-start items-start'>
                    <div className='flex gap-4 w-full sm:flex-col sm:gap-3'>
                        <Input type='text' onChange={onChange} value={myData.first_name} placeholder='' required={true} disabled={false} label={"First Name"} name='first_name' readOnly={true} />
                        <Input type='text' onChange={onChange} value={myData.last_name} placeholder='' required={true} disabled={false} label={"Last Name"} name='last_name' readOnly={true} />
                        <Input type='email' onChange={onChange} value={myData.email} placeholder='' required={true} disabled={false} label={"Email"} name='email' readOnly={true} />
                        <Input type='number' onChange={onChange} value={myData.phone} placeholder='' required={true} disabled={false} label={"Phone"} name='phone' readOnly={true} />
                    </div>
                    {submitBtn && <button disabled={loading} className='disabled:opacity-75 disabled:cursor-wait rounded-3xl border-[2px] border-blue-600 px-6 py-3 sm:px-4 sm:py-2 sm:text-xs text-white bg-blue-600 font-bold flex text-sm justify-center items-center gap-3'>
                        Update
                    </button>}
                </form>
            </div>

            <div className="border-[1px] border-grey-50 rounded-2xl px-4 py-5 mt-4 sm:border-none sm:p-0 sm:mt-4">
                <div>
                    <h1 className="text-black font-bold text-lg flex gap-2 items-center capitalize">Password</h1>
                    <p className="text-sm text-stalle-700 font-medium">Update your password</p>
                </div>
                <form onSubmit={onSubmitPass} className='flex flex-col gap-4 mt-6 justify-start items-start'>
                    <div className='flex gap-3 w-1/3 flex-col sm:w-full sm:gap-3'>
                        <Input type='password' onChange={onChangePassword} value={password.current_password} placeholder='' required={true} disabled={false} label={"Current Password"} name='current_password' readOnly={false} />
                        <Input type='password' onChange={onChangePassword} value={password.new_password} placeholder='' required={true} disabled={false} label={"New Password"} name='new_password' readOnly={false} />
                        <Input type='password' onChange={onChangePassword} value={password.confirm_new_password} placeholder='' required={true} disabled={false} label={"Confirm New Password"} name='confirm_new_password' readOnly={false} />
                    </div>
                    <button disabled={loading} className='disabled:opacity-75 disabled:cursor-wait rounded-3xl border-[2px] border-blue-600 px-6 py-3 sm:px-4 sm:py-2 sm:text-xs  text-white bg-blue-600 font-bold flex text-sm justify-center items-center gap-3'>
                        Update
                    </button>
                </form>
            </div>

        </div>
    )
}

export default PersonalInfo
