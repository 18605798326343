import React, { useState } from 'react'
import { FiEye } from "react-icons/fi";

interface Props {
    label: string;
    onChange: any;
    required: boolean;
    disabled: boolean;
    placeholder: string;
    value: string;
    type: string; 
    name: string;
    readOnly: boolean,

}

const Input: React.FC<Props> = ({ label, type, onChange, disabled, required, placeholder, value, name, readOnly }: Props) => {
    const [readonly, setReadonly] = useState<boolean>(readOnly)
    const changeType = () => {
        const input = document.querySelector(`input[name=${name}]`) as HTMLInputElement;
        if (input.type === 'password') {
            input.type = 'text';
        } else {
            input.type = 'password';
        }
    }
    return (
        <div className='flex flex-col gap-1 relative w-full'>
            <label className='text-black text-sm sm:text-xs font-bold'>{label}</label>

            <input
                id={name}
                type={type}
                onChange={onChange}
                required={required}
                disabled={disabled}
                placeholder={placeholder}
                name={name}
                defaultValue={value}
                readOnly={readonly}
                className='border-[2px] border-grey-50/75 focus:border-black/50 transition-all sm:text-xs ease-in duration-150 rounded-lg sm:px-4 sm:py-3 px-5 py-4 font-medium placeholder:text-black/50 text-black text-sm outline-none w-full' />
       
        {type === 'password' && <FiEye onClick={changeType} className='absolute text-black/50 text-xl right-5 bottom-4 cursor-pointer sm:text-lg' />}
        {readonly && <p onClick={()=>setReadonly(false)} className='cursor-pointer absolute right-5 bottom-4 text-blue-600 text-sm font-bold sm:text-xs'>Edit</p>}
        </div>
    )
}

export default Input
