import React, { useContext, useEffect } from 'react'
import { Context } from '../state/Provider'
import { RxCross2 } from "react-icons/rx";
import Input from './Input';
import Textarea from './Textarea';
import { createDocType } from '../services/createDocType';
import { toast } from 'react-toastify';

interface DocType {
    name: string;
    allowed_size: number;
    max_documents: number;
    description: string;
    checklist_id: number

}

function CreateDocType() {
    const { createDocTypePopup, setCreateDocTypePopup } = useContext(Context)
    const [loading, setLoading] = React.useState<boolean>(false)
    const [docType, setDocType] = React.useState<DocType>({
        name: '',
        allowed_size: 0,
        max_documents: 0,
        description: '',
        checklist_id: createDocTypePopup
    } as DocType)

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDocType({ ...docType, [e.target.name]: e.target.value })
    }

    const onSubmit = async(e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setLoading(true)
        try {
            const response = await createDocType(docType)
            if (response.status === 200) {
                (document.getElementById('name') as HTMLInputElement).value = '';
                (document.getElementById('allowed_size') as HTMLInputElement).value = '';
                (document.getElementById('max_documents') as HTMLInputElement).value = '';
                (document.getElementById('description') as HTMLInputElement).value = '';
                setDocType({
                    name: '',
                    allowed_size: 0,
                    max_documents: 0,
                    description: '',
                    checklist_id: createDocTypePopup
                } as DocType)
                setCreateDocTypePopup(0)
                toast.success('DocType created successfully')
            }
            else
                toast.error(response.data.detail)
        } catch (error) {
            console.log(error);
        }
        setLoading(false)
    }

    useEffect(() => {
        setDocType({ ...docType, checklist_id: createDocTypePopup })
    }, [createDocTypePopup])

    return (
        <div className={`h-screen w-full flex justify-center items-center fixed top-0 right-0 bg-black/50 z-[50] transition-all ease-in duration-300`}>
            <div className='flex w-2/5 bg-white p-4 rounded-2xl flex-col gap-7 overflow-hidden sm:w-[90%] sm:gap-4'>
                <div className='flex justify-between items-center w-full'>
                    <h2 className='text-black text-lg font-semibold'>Create DocType</h2>
                    <span onClick={() => { setCreateDocTypePopup(0) }} className='cursor-pointer bg-grey-50/50 rounded-md border-[1px] border-grey-150/20 p-1.5'>
                        <RxCross2 className='text-black text-xl' />
                    </span>
                </div>
                <form onSubmit={onSubmit} className='flex flex-col gap-4 justify-start items-start sm:gap-3'>
                    <Input label='DocType Name' type='text' placeholder='Enter DocType Name' required={true} readOnly={false} onChange={onChange} name='name' disabled={false} value='' />
                    <div className='flex gap-4 w-full sm:gap-3'>
                        <Input label='Allowed Size' type='number' placeholder='Enter Size' required={true} readOnly={false} onChange={onChange} name='allowed_size' disabled={false} value='' />
                        <Input label='Max Documents' type='number' placeholder='Enter max' required={true} readOnly={false} onChange={onChange} name='max_documents' disabled={false} value='' />
                    </div>
                        <Textarea label='Description' placeholder='Enter description' required={false} readOnly={false} onChange={onChange} name='description' disabled={false} value='' />
                    <button disabled={loading} type='submit' className='disabled:opacity-60 disabled:cursor-wait bg-blue-600 text-white rounded-3xl py-2 px-4 font-medium'>Create</button>
                </form>
            </div>
        </div>
    )
}

export default CreateDocType
