import { HiOutlineDotsVertical } from "react-icons/hi"
import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { BiSolidPencil } from "react-icons/bi"
import { GoTrash } from "react-icons/go"
import { Applicant } from "../types"


interface MobileViewProps {
    applicant: Applicant;
    deleteApplicant: (id: string) => void
}



const MobileViewApplicant: React.FC<MobileViewProps> = ({ applicant, deleteApplicant }) => {
    const [menu, setMenu] = useState<boolean>(false)
    const navigate = useNavigate()
    const menuRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
                setMenu(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [menuRef]);
    return (
        <div className="p-2 border-[1px] border-grey-50 rounded-lg flex flex-col gap-2 relative">
            <div className="flex items-center justify-end gap-1.5">
                <span onClick={(e) =>{e.stopPropagation(); setMenu(!menu)}} className="font-bold p-1 border-[1px] border-grey-50  rounded-full">
                    <HiOutlineDotsVertical className='text-black text-base' />
                </span>

                {menu && <div ref={menuRef} className="absolute top-9 right-3 bg-white border-stalle-500/30 shadow-md border-[1px] p-2 rounded-lg w-36 flex flex-col gap-1.5 z-30">
                    <div onClick={(e) =>{e.stopPropagation(); navigate(window.location.pathname.startsWith('/applicants')?  `/applicants/${applicant.id}` :  `/admin/${applicant.id}`)}}  className="flex justify-start items-center gap-2">
                        <span className="flex justify-center items-center p-1.5 rounded-xl bg-blue-600/15 cursor-pointer">
                            <BiSolidPencil className='text-blue-600 text-md' />
                        </span>
                        <p className="text-xs text-black font-normal">Edit</p>
                    </div>
                    <div onClick={() => deleteApplicant(String(applicant.id))} className="flex justify-start items-center gap-2">
                        <span className="flex justify-center items-center p-1.5 rounded-xl bg-red/20 cursor-pointer">
                            <GoTrash className='text-red text-sm' />
                        </span>
                        <p className="text-xs text-black font-normal">Delete</p>
                    </div>
                </div>}
            </div>
            <div className="flex justify-between items-start gap-2 mt-0">
                <span>
                    <h1 className="font-bold text-stalle-500 text-xs uppercase">Name</h1>
                    <h1 className="font-normal text-black text-xs capitalize">{applicant.full_name}</h1>
                </span>
               {window.location.pathname.startsWith('/admin') && <span className="min-w-[40%]">
                    <h1 className="font-bold text-stalle-500 text-xs uppercase">Active</h1>
                    <h1 className="font-normal text-black text-xs capitalize">{applicant.is_active? 'YES': 'NO'}</h1>
                </span>}
            </div>
            <div className="flex justify-between items-start gap-2 mt-0">
                <span>
                    <h1 className="font-bold text-stalle-500 text-xs uppercase">Email</h1>
                    <h1 className="font-normal text-black text-xs lowercase">{applicant.email}</h1>
                </span>
                {window.location.pathname.startsWith('/admin') && <span className="min-w-[40%]">
                    <h1 className="font-bold text-stalle-500 text-xs uppercase">Super User</h1>
                    <h1 className="font-normal text-black text-xs capitalize">{applicant.is_superuser? 'YES': 'NO'}</h1>
                </span>}
            </div>
        </div>
    )
}


export default MobileViewApplicant