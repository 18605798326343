import React, { useContext, useEffect } from 'react'
import { IoArrowBack } from 'react-icons/io5'
import { useNavigate, useParams } from 'react-router-dom'
import Input from '../components/Input'
import Select from 'react-select'
import { CountryDropdown } from 'react-country-region-selector';
import { createApplication } from '../services/createApplication'
import { toast } from 'react-toastify'
import { getSingleApplication } from '../services/getSingleApplication'
import { updateApplication } from '../services/updateApplication'
import { Context } from '../state/Provider'
import { createApplicant } from '../services/createApplicant'
import { updateApplicant } from '../services/updateApplicant'
import { getSingleApplicant } from '../services/getSingleApplicant'
import { registerUser } from '../services/registerUser'
import { getSingleUser } from '../services/getSingleUser'
import { updateUser } from '../services/updateUser'

interface User {
    first_name: string;
    last_name: string;
    email: string;
    full_name?: string;
    is_active?: boolean;
    is_superuser? : boolean;
}

interface User {
    id: number;
    password?: string;
}

const User: React.FC = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const { setRefreshApplicants } = useContext(Context)

    const [applicant, setApplicant] = React.useState<User>({} as User)

    const [loading, setLoading] = React.useState<boolean>(false)

    const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setApplicant({ ...applicant, [e.target.name]: e.target.value })

    }

    const onsubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setLoading(true)
        try {
            applicant.full_name = applicant.first_name + ' ' + applicant.last_name
            let response
            if (id)
                response = await updateUser(applicant)
            else
                response = await registerUser(applicant.email, applicant.password ?? '', applicant.first_name, applicant.last_name, applicant.full_name)
            if (response.status === 200) {
                if (id)
                    toast.success('User Updated')
                else
                    toast.success('User Created')

                setRefreshApplicants(prevState => !prevState)
                navigate(-1)
            }


        } catch (error) {
            console.log(error);
        }
        setLoading(false)
    }

    const getApp = async () => {
        if (id)
            try {
                const response: User = await getSingleUser(id)
                setApplicant(response)
            } catch (error) {
                console.log(error);
            }

    }

    useEffect(() => {
        getApp()
    }, [id])
    return (
        <div className='flex flex-col gap-4 justify-start items-start py-10 px-8 sm:px-4 sm:py-10'>
            <button onClick={() => navigate(-1)} className='flex items-center gap-1 bg-grey-50 rounded-3xl px-2 py-2 text-black cursor-pointer text-sm sm:text-xs'>
                <IoArrowBack className='text-black text-md' />
                Back
            </button>
            <div>
                <h1 className="text-black font-bold text-lg flex gap-2 items-center capitalize">{id ? 'Edit' : 'New'} Users</h1>
                <p className="text-sm text-stalle-700 font-medium  sm:text-xs">You are {id ? 'updating' : 'creating new'} user </p>
            </div>

            <form onSubmit={onsubmit} className='flex flex-col gap-4 mt-2 justify-start items-start w-full'>
                <div className='flex gap-3 w-full sm:flex-col' >
                    <Input type='text' onChange={onChange} value={applicant.first_name} placeholder='Type here' required={true} disabled={false} label={"First Name"} name='first_name' readOnly={false} />
                    <Input type='text' onChange={onChange} value={applicant.last_name} placeholder='Type here' required={true} disabled={false} label={"Last Name"} name='last_name' readOnly={false} />
                    <Input type='email' onChange={onChange} value={applicant.email} placeholder='Type here' required={true} disabled={false} label={"Email"} name='email' readOnly={false} />
                </div>
                <div className='flex gap-3 w-full sm:flex-col' >
                    <span className='w-1/3'>
                        {!id && <Input type='password' onChange={onChange} value={applicant.password || ''} placeholder='Type here' required={true} disabled={false} label={"Password"} name='password' readOnly={false} />}
                        {id && <div className='flex flex-col gap-1 relative w-full'>
                            <label className='text-black text-sm sm:text-xs font-bold'>Active</label>
                            <div className='flex justify-start items-start text-black text-sm sm:text-xs gap-4'>
                                <span className='flex justify-start items-center gap-1'>
                                    <input type='radio' onChange={() => setApplicant(s => ({ ...s, is_active: true }))} name='is_active' checked={applicant.is_active} className='mr-0' required={true} disabled={false} />
                                    YES
                                </span>
                                <span className='flex justify-start items-center gap-1'>
                                    <input type='radio' onChange={() => setApplicant(s => ({ ...s, is_active: false }))} name='is_active' checked={!applicant.is_active} className='mr-0' required={true} disabled={false} />
                                    No
                                </span>
                            </div>
                        </div>}
                    </span>
                    <span className='w-1/3'>
                    {id && <div className='flex flex-col gap-1 relative w-full'>
                            <label className='text-black text-sm sm:text-xs font-bold'>Super user</label>
                            <div className='flex justify-start items-start text-black text-sm sm:text-xs gap-4'>
                                <span className='flex justify-start items-center gap-1'>
                                    <input type='radio' onChange={() => setApplicant(s => ({ ...s, is_superuser: true }))} name='is_superuser' checked={applicant.is_superuser} className='mr-0' required={true} disabled={false} />
                                    YES
                                </span>
                                <span className='flex justify-start items-center gap-1'>
                                    <input type='radio' onChange={() => setApplicant(s => ({ ...s, is_superuser: false }))} name='is_superuser' checked={!applicant.is_superuser} className='mr-0' required={true} disabled={false} />
                                    No
                                </span>
                            </div>
                        </div>}
                    </span>
                    <span className='w-1/3'>
                    </span>
                </div>
                <button disabled={loading} className='disabled:opacity-75 disabled:cursor-wait rounded-3xl border-[2px] border-blue-600 px-6 py-3 sm:px-3 sm:py-2 sm:text-xs  text-white bg-blue-600 font-bold flex text-sm justify-center items-center gap-3'>
                    {id ? 'Update' : 'Create'}
                </button>
            </form>
        </div>
    )
}

export default User