import React, { useEffect, useState } from 'react';
import { createContext } from 'react';
import { IApplications, IApplicant, Storage, Checklist, Notification } from '../types';
import { getApplications } from '../services/getApplications';
import { getApplicants } from '../services/getApplicants';
import { getStorage } from '../services/getStorage';
import { useLocation } from 'react-router-dom';
import { getNotification } from '../services/getNotification';

interface IContext {
  applications: IApplications;
  setRefreshApplications: React.Dispatch<React.SetStateAction<boolean>>;
  applicationDetailSideBar: number;
  setApplicationDetailSideBar: React.Dispatch<React.SetStateAction<number>>;

  applicants?: IApplicant;
  setRefreshApplicants: React.Dispatch<React.SetStateAction<boolean>>;

  createDocTypePopup: number;
  setCreateDocTypePopup: React.Dispatch<React.SetStateAction<number>>;

  uploadFilesDocTypePopup: boolean, 
  setuploadFilesCreateDocTypePopup: React.Dispatch<React.SetStateAction<boolean>>;

  docViewerSideBar: any,
  setDocViewerSideBar: React.Dispatch<React.SetStateAction<any>>;
  sidebar: boolean;
  setSidebar: React.Dispatch<React.SetStateAction<boolean>>;

  storage: Storage;

  selectedchecklist: Checklist, 
  setSelectedChecklist: React.Dispatch<React.SetStateAction<Checklist>>;

  mobBackBtn: boolean;

  notification: Notification[];
}

export const Context = createContext<IContext>({} as IContext);

function Provider({ children }: { children: React.ReactNode }) {
  const [applications, setApplications] = useState<IApplications>({ data: [], count: 0 } as IApplications)
  const [refreshApplications, setRefreshApplications] = useState<boolean>(false)

  const [applicants, setApplicants] = useState<IApplicant>({ data: [], count: 0 } as IApplicant)
  const [refreshApplicants, setRefreshApplicants] = useState<boolean>(false)

  const [applicationDetailSideBar, setApplicationDetailSideBar] = useState<number>(0)
  const [createDocTypePopup, setCreateDocTypePopup] = useState<number>(0)
  const [uploadFilesDocTypePopup, setuploadFilesCreateDocTypePopup] = useState<boolean>(false)

  const [docViewerSideBar, setDocViewerSideBar] = useState<any>(false)
  const [selectedchecklist, setSelectedChecklist] = React.useState<Checklist>({} as Checklist)
  const [sidebar, setSidebar] = useState<boolean>(true)
  const [storage, setStorage] = useState<Storage>({}as Storage)
  const [notification, setNotification] = useState<Notification[]>([])
  
  const [mobBackBtn, setMobBackBtn] = useState<boolean>(true)
  const location = useLocation()

  const getApps = async () => {
    try {
      const response: IApplications = await getApplications()
      setApplications(response)
    } catch (error) {
      console.error(error);

    }
  }


  const _getApplicants = async () => {
    try {
      const response: IApplicant = await getApplicants()
      setApplicants(response)
    } catch (error) {
      console.error(error);

    }
  }
  const _getStorage = async () => {
    try {
      const response: Storage = await getStorage()
      setStorage(response)
    } catch (error) {
      console.error(error);

    }
  }

  const _getNotification = async () => {
    try {
      const response: Notification[] = await getNotification()
      setNotification(response)
    } catch (error) {
      console.log(error);
      
    }
  }


  useEffect(() => {
    if(selectedchecklist.id){
      setMobBackBtn(true)
    }
    else if(location.pathname.includes('/create')){
      setMobBackBtn(true)
    }
    else{
      setMobBackBtn(false)
    }
  }, [location.pathname, selectedchecklist])



  useEffect(() => {
    _getApplicants()
    _getStorage()
  }, [refreshApplicants])
  
  useEffect(() => {
    _getApplicants()
    getApps()
    _getNotification()
  }, [refreshApplications])


  let value: IContext = {
    applications,
    setRefreshApplications,

    applicationDetailSideBar,
    setApplicationDetailSideBar,

    applicants,
    setRefreshApplicants,

    createDocTypePopup, 
    setCreateDocTypePopup,

    uploadFilesDocTypePopup, 
    setuploadFilesCreateDocTypePopup,

    docViewerSideBar,
    setDocViewerSideBar,

    sidebar, 
    setSidebar,

    storage,

    selectedchecklist, 
    setSelectedChecklist,

    mobBackBtn,
    notification
  };

  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  );
}

export default Provider;
