import React, { useContext, useEffect, useState } from 'react'
import { CiSearch } from 'react-icons/ci'
import { GoFilter, GoPlus, GoTrash } from 'react-icons/go'
import { getApplicants } from '../services/getApplicants'
import { Context } from '../state/Provider'
import { Link } from 'react-router-dom'
import { BiSolidPencil } from 'react-icons/bi'
import { IoEyeOutline } from 'react-icons/io5'
import { toast } from 'react-toastify'
import { deleteApplicant } from '../services/deleteApplicant'
import { BsStars } from 'react-icons/bs'
import { getUsers } from '../services/getUsers'
import { deleteUser } from '../services/deleteUser'
import MobileViewApplicant from '../components/MobileViewApplicant'

const Users: React.FC = () => {
    const [users, setUsers] = useState<any>([])
    const [allUsers, setAllUsers] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(false)

    const _deleteApplicant = async (id: string) => {
        const confirm = window.confirm('Are you sure you want to delete this application?')
        if (!confirm) return
        setLoading(true)
        try {
            const response = await deleteUser(id)
            if (response.status === 204) {
                toast.success("User deleted.")
                setUsers({ ...users, data: users.data.filter((user: any) => user.id !== id) })
                setAllUsers({ ...allUsers, data: allUsers.data.filter((user: any) => user.id !== id) })
            }
            else
                toast.error(response.data.detail)
        } catch (error) {
            console.error(error);
        }
        setLoading(false)
    }

    const searcUser = (e: any) => {
        const value = e.target.value
        if (value === '') {
            setUsers(allUsers)
        } else {
            const filtered = allUsers.data.filter((user: any) => {
                return user.full_name.toLowerCase().includes(value.toLowerCase()) || user.email.toLowerCase().includes(value.toLowerCase())
            })
            setUsers({ ...allUsers, data: filtered })
        }
    }

    const fetUsers = async () => {
        try {
            const data = await getUsers(0, 100)
            setUsers(data)
            setAllUsers(data)
        } catch (error) {
            console.log(error);

        }
    }

    useEffect(() => {
        fetUsers()
    }, [])
    return (
        <div className='py-10 px-8 sm:px-4'>
            <div className="flex justify-between items-center sm:flex-col sm:items-start sm:gap-3">
                <div>
                    <h1 className="text-black font-bold text-2xl flex gap-2 items-center capitalize">Welcome, {JSON.parse(localStorage.getItem('user') || "{}").first_name}
                        <img src={require('../assets/image 6.png')} />
                    </h1>
                    <p className="text-sm text-stalle-700 font-medium mt-1 sm:text-xs">Here's what's happening with your users today</p>
                </div>
                <div className="flex gap-2">

                    <Link to='create' className="rounded-3xl bg-[#6C6DB3] px-3 py-3 text-white font-semibold flex justify-center items-center capitalize gap-1 sm:text-xs sm:py-2">
                        <GoPlus className='text-white text-xl' />
                        create new user
                    </Link>
                    <Link to='create' className="rounded-3xl bg-blue-600 px-3 py-3 text-white font-semibold flex justify-center items-center capitalize gap-1 sm:text-xs sm:py-2">
                        <BsStars className='text-white text-xl rotate-180' />
                        Start with AI
                    </Link>
                </div>
            </div>
            <div className="border-[1px] border-grey-50 rounded-2xl mt-10 sm:mt-0 sm:border-none">
                <div className="flex justify-between items-end px-4 py-5 sm:px-0 sm:gap-1 sm:flex-col sm:justify-start sm:items-start">
                    <div>
                        <h1 className="text-black font-bold text-lg flex gap-2 items-center capitalize">Users</h1>
                        <p className="text-sm text-stalle-700 font-medium">You have currently <b>{users?.count}</b> users</p>
                    </div>

                    <div className="flex gap-2 sm:w-full">
                        <div className='bg-grey-50/40 rounded-3xl w-[300px] border-[1px] border-grey-100 flex items-center gap-1 px-4 p-3 sm:w-full sm:p-2 '>
                            <CiSearch className='text-black text-xl' />
                            <input onChange={searcUser} type='text' placeholder='Search for anything' className='w-full font-medium placeholder:text-black/75 text-black/75 h-full text-sm bg-transparent outline-none' />
                        </div>
                    </div>
                </div>


                <div className="overflow-x-auto sm:hidden">
                    <table className="min-w-full lg:table">
                        <thead className="bg-[#F1F5F9]">
                            <tr>
                                <th className="px-6 py-3 text-left text-sm text-black font-bold text-gray-500 capitalize tracking-wider">Name</th>
                                <th className="px-6 py-3 text-left text-sm text-black font-bold text-gray-500 capitalize tracking-wider">Email</th>
                                <th className="px-6 py-3 text-left text-sm text-black font-bold text-gray-500 capitalize tracking-wider whitespace-nowrap">User Type</th>
                                <th className="px-6 py-3 text-left text-sm text-black font-bold text-gray-500 capitalize tracking-wider whitespace-nowrap">Active</th>
                                <th className="px-6 py-3 text-left text-sm text-black font-bold text-gray-500 capitalize tracking-wider w-28">Action</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white">
                            {users?.data?.map((application: any, index: number) => {
                                return (
                                    <tr key={application.id} className="border-b-[1px] border-grey-150/50">

                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 capitalize">{application.full_name}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 lowercase">{application.email}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 capitalize">{application.is_superuser ? 'Supper' : 'User'}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 capitalize">{application.is_active ? 'Yes' : 'No'}</td>

                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 flex justify-start items-center gap-1.5">
                                            <Link to={`/admin/${application.id}`} className="flex justify-center items-center p-2 rounded-xl bg-blue-600/15 cursor-pointer">
                                                <BiSolidPencil className='text-blue-600 text-lg' />
                                            </Link>
                                            <button disabled={loading} onClick={() => _deleteApplicant(`${application.id}`)} className="flex justify-center items-center p-2 rounded-xl bg-red/20 cursor-pointer">
                                                <GoTrash className='text-red text-lg' />
                                            </button>
                                        </td>
                                    </tr>
                                )

                            })}

                        </tbody>
                    </table>

                </div>
                <div className='hidden sm:flex flex-col gap-4'>
                    {users?.data?.map((applicant: any, index: number) => {
                        return <MobileViewApplicant applicant={applicant} deleteApplicant={_deleteApplicant} />
                    })}
                </div>
            </div>
        </div>
    )
}

export default Users
