import React, { useContext, useState } from 'react'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import { Context } from '../state/Provider'
import CreateDocType from '../components/CreateDocType'
import UploadFilesDocType from '../components/UploadFilesDocType'
import DocViewer from '../components/DocViewer'
import ApplicationDetail from '../components/ApplicationDetail'

function Mainlayout({ children }: { children: React.ReactNode }) {
    const { applicationDetailSideBar, createDocTypePopup, uploadFilesDocTypePopup, docViewerSideBar, sidebar, setSidebar } = useContext(Context)

    const [mobDrawer, setMobDrawer] = useState<boolean>(false)
    return (
        <div className='!bg-white'>
            <Navbar mobDrawer={mobDrawer} setMobDrawer={setMobDrawer}/>

            <div className='flex justify-start items-start mt-16 sm:mt-7'>

                <Sidebar sidebar={sidebar} setSidebar={setSidebar} mobDrawer={mobDrawer} setMobDrawer={setMobDrawer}/>

                <div className={`w-full ${sidebar? 'ml-[240px]' : '-ml-0'} overflow-x-hidden transition-all ease-in duration-75 sm:ml-0`}>
                    {children}
                </div>

                {applicationDetailSideBar !== 0 && <ApplicationDetail />}
                {createDocTypePopup !== 0 && <CreateDocType />}
                {uploadFilesDocTypePopup && <UploadFilesDocType />}
                {docViewerSideBar && <DocViewer />}
            </div>

        </div>
    )
}

export default Mainlayout
