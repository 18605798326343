import axios from 'axios';
// import { Application } from '../types'; // Adjust path to where the types are defined
import { BASE_SERVER } from '../constants';
import { getToken } from '../utils/getToken';
import { Applicant, Checklist } from '../types';
interface User {
    first_name: string;
    last_name: string;
    email: string;
    full_name?: string;
}

interface User {
    id: number;
    password?: string;
}

export const getSingleUser = async (id:string): Promise<User> => {
    try {
        const response = await axios.get<User>(`${BASE_SERVER}/users/${id}`, {
            headers: {
                'Authorization': `Bearer ${getToken()}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        // Handle errors or re-throw for the caller to handle
        throw error;
    }
};
