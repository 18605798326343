import axios from 'axios';
import { Applicant } from '../types'; // Adjust path to where the types are defined
import { BASE_SERVER } from '../constants';
import { getToken } from '../utils/getToken';


export const getUserProfile = async (): Promise<Applicant> => {
    try {
        const response = await axios.get<Applicant>(`${BASE_SERVER}/users/me`, {
            headers: {
                'Authorization': `Bearer ${getToken()}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        // Handle errors or re-throw for the caller to handle
        throw error;
    }
};
