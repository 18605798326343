import axios, { AxiosResponse } from "axios";
import { BASE_SERVER } from "../constants";
import { getToken } from "../utils/getToken";

interface User {
    first_name: string;
    last_name: string;
    email: string;
    full_name?: string;
    is_active?: boolean;
    is_superuser? : boolean;
}

interface User {
    id: number;
    password?: string;
}

interface FailureResponse {
    detail?: string;
}

export const updateUser = async (
    user: User
): Promise<AxiosResponse<FailureResponse | any>> => {
   
    try {
        const response: AxiosResponse<any> = await axios.patch(`${BASE_SERVER}/users/${user.id}`, user, {
            headers: {
                'Authorization': `Bearer ${getToken()}`,
                'Content-Type': 'application/json',
            },
        });
        return response;
    } catch (error: any) {
        if (error.response) {
            return error.response;
        } else if (error.request) {
            return {
                data: { detail: "No response from server" },
                status: 0,
                statusText: '',
                headers: {},
                config: {},
                request: error.request
            } as AxiosResponse<FailureResponse>;
        } else {
            return {
                data: { detail: error.message },
                status: 0,
                statusText: '',
                headers: {},
                config: {},
                request: null
            } as AxiosResponse<FailureResponse>;
        }
    }
};
