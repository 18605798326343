import React, { useState } from 'react'
import { FiEye } from "react-icons/fi";

interface Props {
    label: string;
    onChange: any;
    required: boolean;
    disabled: boolean;
    placeholder: string;
    value: string;
    name: string;
    readOnly: boolean,

}

const Textarea: React.FC<Props> = ({ label, onChange, disabled, required, placeholder, value, name, readOnly }: Props) => {

    return (
        <div className='flex flex-col gap-1 relative w-full'>
            <label className='text-black text-sm font-bold'>{label}</label>

            <textarea
                id={name}
                onChange={onChange}
                required={required}
                disabled={disabled}
                placeholder={placeholder}
                name={name}
                defaultValue={value}
                readOnly={readOnly}
                className='border-[2px] border-grey-50/75 focus:border-black/50 transition-all ease-in duration-150 rounded-lg px-5 py-4 font-medium placeholder:text-black/50 text-black text-sm outline-none' >
                </textarea>
       
        </div>
    )
}

export default Textarea
