import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { GOOGLE_CLIENT_ID, GOOGLE_CLIENT_SECERET, G_REDIRECT_URL } from '../constants';
import axios from 'axios';
import { login } from '../services/login';
import { registerUser } from '../services/registerUser';
import { toast } from 'react-toastify';
import { getUserProfile } from '../services/getUserProfile';


interface TokenResponse {
    access_token: string;
}
interface UserInfoResponse {
    id: string;
    email: string;
    verified_email: boolean;
    name: string;
    given_name: string;
    family_name: string;
    picture: string;
}

function Logging() {

    const [code, setCode] = useState<string>("");
    const [userInfo, setUserInfo] = useState<UserInfoResponse | null>(null);
    const navigate = useNavigate()

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        for (const [key, value] of searchParams.entries()) {
            if (key === 'code') {
                setCode(value);
            }
        }
    }, []);

    const decode = async () => {
        try {
            const response = await axios.post<TokenResponse>('https://oauth2.googleapis.com/token', {
                code,
                client_id: GOOGLE_CLIENT_ID,
                client_secret: GOOGLE_CLIENT_SECERET,
                redirect_uri: G_REDIRECT_URL,
                grant_type: 'authorization_code',
            });

            const { access_token } = response.data;

            const userInfoResponse = await axios.get<UserInfoResponse>('https://www.googleapis.com/oauth2/v2/userinfo', {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            });

            const userInfo = userInfoResponse.data;
            setUserInfo(userInfo)

        } catch (error) {
            toast.error('An error occured, please try again');
            let lastpath = localStorage.getItem('lastPath') ?? '/login';
            navigate(lastpath)
        }
    }

    useEffect(() => {
        if (code !== "")
            decode()
    }, [code])

    const handleUser = async () => {
        if (userInfo) {
            try {
                let response;
                if (localStorage.getItem('lastPath') === '/login') {
                    response = await login(userInfo.email, userInfo.id);
                    if (response.status === 200 && 'access_token' in response.data) {
                        localStorage.setItem('token', response.data.access_token || '');
                        const data = await getUserProfile();
                        localStorage.setItem('user', JSON.stringify(data))
                        window.location.pathname = '/';
                    } else {
                        toast.error(response.data.detail || 'Login failed. Please try again.');
                        let lastpath = localStorage.getItem('lastPath') ?? '/login';
                        navigate(lastpath)
                    }
                }
                else {
                    response = await registerUser(userInfo.email, userInfo.id, userInfo.given_name, userInfo.family_name, userInfo.name);
                    if (response.status === 200) {
                        localStorage.setItem('user', JSON.stringify(response.data))
                        toast.success('Account created successfully');
                        navigate('/login');
                    } else {
                        toast.error(response.data.detail || 'Registration failed. Please try again.');
                        let lastpath = localStorage.getItem('lastPath') ?? '/login';
                        navigate(lastpath)
                    }
                }



            } catch (error) {
                toast.error('An error occured, please try again');
                let lastpath = localStorage.getItem('lastPath') ?? '/login';
                navigate(lastpath)
                console.log(error);
            }
        }
    }

    useEffect(() => {
        if (userInfo !== null)
            handleUser()
    }, [userInfo])

    return (
        <div className='flex flex-1 h-screen w-full justify-center items-center flex-col gap-4'>
            <img src='https://cdn.pixabay.com/animation/2023/10/08/03/19/03-19-26-213_512.gif'
                className='w-32 sm:w-16'
            />
            <p className='text-lg text-center font-bold text-[#2e3a94] sm:text-sm'>Loading ...</p>
        </div>
    )
}

export default Logging
