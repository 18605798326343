import axios, { AxiosResponse } from 'axios';
import { getToken } from '../utils/getToken';
import { BASE_SERVER } from '../constants';

interface FailureResponse {
    status: number;
    detail?: string;
}

interface SuccessResponse {
    share_link: string;
}

type GenerateShareLinkResponse = SuccessResponse | FailureResponse;

export const generateShareLinkApplication = async (
    id: number,
): Promise<AxiosResponse<GenerateShareLinkResponse>> => {
    try {
        const response: AxiosResponse<GenerateShareLinkResponse> = await axios.post(`${BASE_SERVER}/share/application/${id}`, {}, {
            headers: {
                'Authorization': `Bearer ${getToken()}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        return response;
    } catch (error: any) {
        if (axios.isAxiosError(error) && error.response) {
            return error.response;
        } else if (error.request) {
            return {
                data: { status: 0, detail: 'No response from server' },
                status: 0,
                statusText: '',
                headers: {},
                config: {},
                request: error.request
            } as AxiosResponse<GenerateShareLinkResponse>;
        } else {
            return {
                data: { status: 0, detail: error.message },
                status: 0,
                statusText: '',
                headers: {},
                config: {},
                request: null
            } as AxiosResponse<GenerateShareLinkResponse>;
        }
    }
};
